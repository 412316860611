import React, { useState } from "react";
import { Footer, Navbar, } from "components";
import { RightSideMenu } from "megaComponents";
import { useNavigate, useParams } from "react-router";
import { Formik, Form, ErrorMessage } from "formik";

import * as Yup from "yup";
import axios from "axios";
import { baseURL } from "api/baseURL";
import HelmetComponent from "components/HelmetComponent";

const ContactUs = () => {
  const navigate = useNavigate();
  const [set, setTrue] = useState(false);
  const param = useParams();

  const { id } = param;

  const initialValues = {
    name: "",
    email: "",
    subject: "",
    message: "",
  };
  const validation = Yup.object({
    name: Yup.string().required("this field is Required"),
    email: Yup.string().required("this field is Required"),
    subject: Yup.string().required("this field is Required"),
    message: Yup.string().required("this field is Required"),
  });

  return (
    <React.Fragment>
      
        {/* <HelmetComponent
        title="Contact Us - mymobilesguru.com"
        description="Contact Us"
        image="https://picsum.photos/300"
      /> */}

      <Navbar />
      <div className="mainContent mt-7 flex flex-col lg:flex-row px-1 lg:px-10">
        <div className="lg:w-[20%] order-2 ">
          <div className="flex flex-col">
            <button
              className="w-auto border-2 bg-transparent p-1 mt-5 lg:mt-0  rounded-[1rem]"
              onClick={() => navigate("/advancesearch")}
            >
              Advance Search
            </button>
            <button
              className="w-auto border-2 bg-transparent mt-8 p-1  rounded-[1rem]"
              onClick={() => navigate("/reviews")}
            >
              Videos Reviews
            </button>
            <button
              className="w-auto text-white bg-[#F4871E] font-semibold text-md mt-3 p-1 pl-[2rem] pr-[2rem] rounded-[1rem]"
              onClick={() => navigate("/uploadad")}
            >
              Buy And Sell <br /> Smart Phone
            </button>
          </div>
          {/* 
          {rightMenu.map(({ title, heading, description }) => {
            return (
              <FilterCard title={title} heading={heading}>
                {description.map((item) => {
                  return <h1>{item}</h1>;
                })}
              </FilterCard>
            );
          })} */}
          <RightSideMenu />
        </div>

        <div className="w-full lg:w-[80%] order-1 h-full  pb-0 lg:pb-[20rem]  flex flex-col lg:pl-7 lg:pr-7 mt-[1rem]  lg:ml-3 lg:mr-3 lg:mt-0  lg:order-2 bg-center ">
          <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={async (value, actions) => {
              try {
                setTrue(true);
                const { data } = await axios.post(
                  `${baseURL}/api/v1/contactus`,
                  value
                );

                data.success && alert("message added");
                actions.resetForm();
                data && setTrue(false);
              } catch (err) {
                console.log(err);
              }
            }}
          >
            {(formik) => (
              <Form>
                <div className="flex-col justify-center">
                  <div className="h-[12rem] video w-full lg:w-full rounded-3xl bg-cover lg:bg-cover bg-no-repeat  bg-[url('assets/images/newsBanner.png')]">
                    <h1 className=" p-10 lg:p-[5rem] lg:pl-16 text-2xl lg:text-5xl font-bold text-white">
                      Contact Us
                    </h1>
                  </div>
                </div>
                <div className="Contactus w-full  ">
                  <div className="w-full text-center mt-5">
                    <button className="w-full lg:w-[70%] text-center text-white font-medium  bg-buttonBgColor bg-gradient-to-b text-sm lg:text-md p-2 pl-10 rounded-[1rem] mt-5 from-buttonBgColorGradian1 to-buttonBgcolorGradian2">
                      Feedback
                    </button>
                  </div>
                  <div className="flex-col w-full mt-[4rem]  text-center">
                    <div className="lg:ml-32 mt-5 w-full lg:w-[70%] text-md font-semibold flex flex-col text-left ">
                      <label htmlFor="">Your Name</label>
                      <input
                        type="text"
                        className="p-3 border mt-3 border-black rounded-lg"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                      />
                      <ErrorMessage
                        component="div"
                        className="text-red-700 text-sm"
                        name="name"
                      />
                    </div>
                    <div className="lg:ml-32 mt-5 w-full lg:w-[70%] text-md font-semibold flex flex-col text-left ">
                      <label htmlFor="">Your Email</label>
                      <input
                        type="email"
                        value={formik.values.email}
                        className="p-3 border mt-3 border-black rounded-lg"
                        name="email"
                        onChange={formik.handleChange}
                      />
                      <ErrorMessage
                        component="div"
                        className="text-red-700 text-sm"
                        name="email"
                      />
                    </div>
                    <div className="lg:ml-32 mt-5 w-full lg:w-[70%] text-md font-semibold flex flex-col text-left ">
                      <label htmlFor="">Subject</label>
                      <input
                        type="text"
                        value={formik.values.subject}
                        className="p-3 border mt-3 border-black rounded-lg"
                        name="subject"
                        onChange={formik.handleChange}
                      />
                      <ErrorMessage
                        component="div"
                        className="text-red-700 text-sm"
                        name="subject"
                      />
                    </div>
                    <div className="lg:ml-32 mt-5 w-full lg:w-[70%] text-md font-semibold flex flex-col text-left ">
                      <label htmlFor="">Massage</label>

                      <textarea
                        value={formik.values.message}
                        name="message"
                        id=""
                        cols="30"
                        rows="10"
                        className="p-3 border mt-3 border-black rounded-lg"
                        onChange={formik.handleChange}
                      ></textarea>
                      <ErrorMessage
                        component="div"
                        className="text-red-700 text-sm"
                        name="message"
                      />
                    </div>
                    <div className="w-full text-center mt-5">
                      <button
                        className="w-full lg:w-[20%] text-center text-white font-medium  bg-buttonBgColor bg-gradient-to-b text-sm lg:text-md p-2 rounded-[1rem] mt-5 from-buttonBgColorGradian1 to-buttonBgcolorGradian2"
                        type="submit"
                      >
                        Send
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          ;
        </div>
      </div>
      <div className="mt-[10rem]">
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default ContactUs;
