import {
  MOBILE_FANS_FAIL,
  MOBILE_FANS_REQUEST,
  MOBILE_FANS_SUCCESS,
  MOBILE_LATEST_FAIL,
  MOBILE_LATEST_REQUEST,
  MOBILE_LATEST_SUCCESS,
} from "redux/Constant/Constant";

export const latestReducer = (state = { latestMobile: [] }, action) => {
  switch (action.type) {
    case MOBILE_LATEST_REQUEST:
      return { loading: true };
    case MOBILE_LATEST_SUCCESS:
      return { loading: false, latestMobile: action.payload };
    case MOBILE_LATEST_FAIL:
      return { loading: false, error: true, error: action.payload };
    default:
      return state;
  }
};

export const fansReducer = (state = { fans: [] }, action) => {
  switch (action.type) {
    case MOBILE_FANS_REQUEST:
      return { loading: true };
    case MOBILE_FANS_SUCCESS:
      return { loading: false, fans: action.payload };
    case MOBILE_FANS_FAIL:
      return { loading: false, error: true, error: action.payload };
    default:
      return state;
  }
};
