import { actionTypes } from "redux/actionTypes";
import {
  GADGET_REVIEW_FAIL,
  GADGET_REVIEW_REQUEST,
  GADGET_REVIEW_SUCCESS,
} from "redux/Constant/Constant";

export const gadgetReviewReducer = (state = {}, action) => {
  switch (action.type) {
    case GADGET_REVIEW_REQUEST:
      return { loading: true };
    case GADGET_REVIEW_SUCCESS:
      return { loading: false, review: action.payload };
    case GADGET_REVIEW_FAIL:
      return { loading: false, error: true };
    default:
      return state;
  }
};
