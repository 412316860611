import {
  GADGET_BYID_FAIL,
  GADGET_BYID_SUCCESS,
  GADGET_GET_FAIL,
  GADGET_GET_REQUEST,
  GADGET_GET_SUCCESS,
} from "redux/Constant/Constant";

export const GadgetGetReducer = (state = { gadget: [] }, action) => {
  switch (action.type) {
    case GADGET_GET_REQUEST:
      return { loading: true, gadget: [] };
    case GADGET_GET_SUCCESS:
      return { loading: false, gadget: action.payload };

    case GADGET_GET_FAIL:
      return { loading: false, error: true };
    default:
      return state;
  }
};

export const GadgetByIdReducer = (state = { singleGadet: [] }, action) => {
  switch (action.type) {
    case GADGET_GET_REQUEST:
      return { loading: true };
    case GADGET_BYID_SUCCESS:
      return { loading: false, singleGadet: action.payload };
    case GADGET_BYID_FAIL:
      return { loading: false, error: true, error: action.payload };
    default:
      return state;
  }
};
