import axios from "axios";
import { localbaseURL } from "./baseURL";

export async function metaManagement(title, description, thumbnail, URL, pageTitle, alt) {
    axios.post(`${localbaseURL}/`, {
        title, description, thumbnail, URL, pageTitle, alt
    })
    updateMetaClient(title, description, thumbnail, URL, pageTitle, alt)
}

function updateMetaClient(title, description, thumbnail, URL, pageTitle, alt){
    if (document.getElementById("ogDesc").content != description) {
        document.getElementById("ogDesc").content = description;
      }
      if (document.getElementById("metadescription").content != description) {
        document.getElementById("metadescription").content = description;
      }
      if (document.getElementById("ogTitleMain").textContent  != pageTitle) {
        document.getElementById("ogTitleMain").textContent  = pageTitle;
      }
      if (document.getElementById("ogTitle").content != title) {
        document.getElementById("ogTitle").content = title;
      }
      if (document.getElementById("ogImage").content != thumbnail) {
        document.getElementById("ogImage").content = thumbnail;
      }
      if (document.getElementById("ogImage").alt != alt) {
        document.getElementById("ogImage").alt = alt;
      }
      if (document.getElementById("ogURL").content != URL) {
        document.getElementById("ogURL").content = URL;
      }
}