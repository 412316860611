import { createStore, combineReducers, applyMiddleware } from "redux";
import mobileReducer from "./reducers/mobileReducer";
import comparisonReducer from "./reducers/comparisonReducer";
import filterReducer from "./reducers/filterReducer";
import ReduxThunk from "redux-thunk";
import { GadgetByIdReducer, GadgetGetReducer } from "./reducers/GagetReducer";
import { gadgetReviewReducer } from "./reducers/gadgetReviewReducer";
import { fansReducer, latestReducer } from "./reducers/MobileLatestReducer";
const rootReducer = combineReducers({
  mobileReducer,
  comparisonReducer,
  filterReducer,
  gadget: GadgetGetReducer,
  gadgetById: GadgetByIdReducer,
  reviewGadget: gadgetReviewReducer,
  latestPhones: latestReducer,
  mobileByFans: fansReducer,
});

const store = createStore(rootReducer, applyMiddleware(ReduxThunk));
export default store;
