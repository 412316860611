import React, { useState, useEffect } from "react";
import ImageAsset from "./ImageAsset";
import { IoCloseOutline } from "react-icons/io5";
import { BsSearch } from "react-icons/bs";
import { baseURL } from "../api/baseURL";
import axios from "axios";
import { useNavigate } from "react-router";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { isFilterMobile } from "../redux/actions/mobileActions";
import { searchBySearchBar } from "../redux/actions/mobileActions";
import { Link } from "react-router-dom";
import {
  getAllPhonesForComparison,
  getComparisonMobileById,
  getComparisonMobileById1,
  getComparisonMobileById2,
} from "../redux/actions/mobileActions";
import { globalPageNoSetter } from "screens/HomeScreen";
const PhoneDetails = [
  {
    value: "Review",
    hrefLink: "/reviews ",
  },

  {
    value: "News",
    hrefLink: "/news ",
  },
];

const Acessories = [
  {
    value: "Comparisons",
    hrefLink: "/comparison",
  },

  {
    value: "Upload Add",
    hrefLink: "/signin ",
  },
];

const Navbar = () => {
  const location = useLocation();

  const { allMobiles, moreMobile, isFilter } = useSelector(
    (state) => state.mobileReducer
  );

  const {
    allMobilesForComparison,
    firstComparisonMobile,
    secondComparisonMobile,
  } = useSelector((state) => state.comparisonReducer);

  const [searchBar1, setSearchBar1] = useState("");
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllPhonesForComparison());
  }, []);
  const handleIsFilter = (value) => dispatch(isFilterMobile(value));
  const handleSearchBar = (value) => {
    dispatch(searchBySearchBar(value, navigate));
  };
  const Searchmodel = (e) => {
    setSearchValue(e.target.value.toLowerCase());
  };
  // let a;
  const [searchData, setSearchData] = useState([]);
  const [display, setDisplay] = useState(false);

  const searchHandler = async () => {
    try {
      const res = await axios.post(`${baseURL}/api/v1/mobiles/search`, {
        searchValue,
      });
      setDisplay(true);
      setSearchData(res.data);
    } catch (err) {
      console.log(err);
    }
  };
  if (searchValue === [] || "") {
    setDisplay(0);
  }

  return (
    <div className="flex flex-col mb-16  md:z-10 lg:mb-0 relative lg:z-0">
      <div className="flex flex-col lg:flex-row mt-1">
        {/* logo wrapper big screen */}
        <div className="newLogo hidden lg:block h-full pt-4 mr-0 ml-0 text-center lg:text-left cursor-pointer lg:mr-10 lg:ml-10">
          <ImageAsset
            className="w-40 h-32 inline lg:block cursor-pointer mt-[-.5rem]"
            src="newLogo"
            onClick={() => {
              navigate("/");
            }}
          />
        </div>
        {/* logo wrapper small screen i think it is not being used. will delete soon  */}
        <div
          onClick={() => {
            navigate("/");
          }}
          className="text-3xl absolute left-8 top-4 cursor-pointer lg:hidden"
        >
          <ImageAsset className="w-20 h-20 mt-[-1.5rem]  " src="newLogo" />{" "}
        </div>

        {/* navigation toggle button small screen */}

        <div
          onClick={() => setOpen(!open)}
          className="text-3xl absolute right-8 top-4  cursor-pointer lg:hidden"
        >
          <ion-icon name={open ? "close" : "menu"}></ion-icon>
        </div>

        {/* navbar for big and small screens */}

        <div
          className={`md:flex md:items-center  z-50 md:pb-0 flex-col bg-gradient-to-b from-buttonBgcolorGradian2 to-buttonBgColorGradian1
            sm:w-full
           mt-4 lg:mt-2 lg:ml-2 absolute bg-backgroundGreenColor md:bg-white lg:static bg-red md:z-auto  left-0 pb-2
           lg:w-[56%] md:pl-0 pl-9 transition-all duration-500 ease-in ${
             open ? "top-5 " : "top-[-490px]"
           }`}
        >
          <div className="mainnavContent   w-full  z-50">
            <nav className="pl-10 pr-10  lg:pl-0 lg:pr-0 mx-auto">
              <ul className="flex flex-col lg:flex-row justify-between text-sm  space-y-1 items-baseline cursor-pointer">
                {/* home */}
                <li>
                  <button className=" text-white font-semibold py-2 px-4 rounded inline-flex items-center hover:bg-buttonBgColorGradian1">
                    <span
                      className="mr-1"
                      onClick={() => {
                        navigate("/");
                      }}
                    >
                      Home
                    </span>
                  </button>
                </li>
                {/* mobile list */}

                <li className="z-10 mt-0">
                  <div>
                    <div className="group inline-block relative">
                      <button className="text-white  font-semibold py-2 px-4 rounded inline-flex items-center hover:bg-buttonBgColorGradian1">
                        <span className="mr-1">Mobile List</span>
                        <svg
                          className="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </button>
                      <ul className="lg:absolute  hidden text-gray-700 pt-1 group-hover:block">
                        <li
                          onClick={() => {
                            globalPageNoSetter(1);
                            navigate("/mobilelist", {
                              state: {
                                isFilter: false,
                              },
                            });
                            handleIsFilter(false);
                          }}
                        >
                          <span className=" bg-gray-200 hover:bg-gray-400 py-2 px-4 lg:w-[11.5rem] block whitespace-no-wrap">
                            New Mobiles
                          </span>
                        </li>
                        <li
                          onClick={() => {
                            globalPageNoSetter(1);
                            navigate("/mobilelist", {
                              state: {
                                title: "OldMobiles",
                                isFilter: true,
                              },
                            });
                            handleIsFilter(true);
                          }}
                        >
                          <span className=" bg-gray-200 hover:bg-gray-400 py-2 px-4 lg:w-[11.5rem] block whitespace-no-wrap">
                            Old Mobiles
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                {/* news */}

                <li>
                  <button
                    className="text-white  font-semibold  py-2 px-4 rounded inline-flex items-center hover:bg-buttonBgColorGradian1"
                    onClick={() => {
                      navigate("/news");
                    }}
                  >
                    <span className="mr-1">News</span>
                  </button>
                </li>
                {/* reviews */}

                <li>
                  <button
                    className="text-white  font-semibold  py-2 px-4 rounded inline-flex items-center hover:bg-buttonBgColorGradian1"
                    onClick={() => {
                      navigate("/reviews");
                    }}
                  >
                    <span className="mr-1">Reviews</span>
                  </button>
                </li>
                {/* others */}

                <li className=" z-10">
                  <div>
                    <div className="group inline-block relative">
                      <button className="text-white  font-semibold py-2 px-4 rounded inline-flex items-center hover:bg-buttonBgColorGradian1">
                        <span className="mr-1">Others</span>
                        <svg
                          className="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </button>
                      <ul className=" lg:absolute hidden text-gray-700 pt-1 group-hover:block">
                        {Acessories.map(({ hrefLink, value }) => {
                          return (
                            <li className="">
                              <span
                                className=" bg-gray-200 hover:bg-gray-400 py-2 px-4 lg:w-[11.5rem] block whitespace-no-wrap"
                                onClick={() => {
                                  if (value === "Upload Add") {
                                    // alert("coming")
                                    if (
                                      localStorage.getItem("phoneNumber") !== ""
                                    ) {
                                      // alert("dkpodc")
                                      navigate("/uploadad");
                                      //
                                    } else {
                                      navigate("/signin");
                                    }
                                  } else {
                                    navigate(hrefLink);
                                  }
                                }}
                              >
                                {value}
                              </span>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </li>
                {/* blogs */}

                <li>
                  <button
                    className="text-white  font-semibold  py-2 px-4 rounded inline-flex items-center hover:bg-buttonBgColorGradian1"
                    onClick={() => {
                      navigate("/blogs");
                    }}
                  >
                    <span className="mr-1">Blogs</span>
                  </button>
                </li>
                {/* gadget list link */}

                <li>
                  <button
                    className="text-white  font-semibold  py-2 px-4 rounded inline-flex items-center hover:bg-buttonBgColorGradian1"
                    onClick={() => {
                      navigate("/gadgetList");
                    }}
                  >
                    <span className="mr-1">Gadgets List</span>
                  </button>
                </li>
                {/* contact us link */}

                <li>
                  <button
                    className="text-white  font-semibold  py-2 px-4 rounded inline-flex items-center hover:bg-buttonBgColorGradian1 outline-none "
                    onClick={() => {
                      navigate("/contactus");
                    }}
                  >
                    <span className="mr-1">Contact us </span>
                  </button>
                </li>
              </ul>
            </nav>
          </div>

          <div className="relative w-[95%] ">
            <input
              onKeyPress={(e) => {
                if (e.key == "Enter") {
                  if (e.target.value == "") {
                    alert("please Enter Full Brand Name");
                  }
                }
              }}
              className="bg-customGray rounded-full border-none
               w-full pl-6 mt-4 text-blue-500 px-2 py-2 mb-4 outline-none"
              type="text"
              // value={searchValue}
              placeholder="Search by brand name"
              // onChange={Searchmodel}
              value={searchBar1}
              onChange={(e) => setSearchBar1(e.target.value)}
            />
            <div
              className={` zIndex: {"-1" }absolute text-white lg:w-[70%] md:w-[90%] rounded-md py-2 px-4 z-50 mx-auto ${
                !searchBar1 ? "hidden" : ""
              }`}
            >
              {allMobilesForComparison
                .filter((val) => {
                  if (searchBar1 === "") {
                    return val;
                  } else if (
                    val.mobile.toLowerCase().includes(searchBar1.toLowerCase())
                  ) {
                    return val;
                  }
                })
                .map((data, item, index, key) => {
                  return (
                    <div className={`z-50 cursor-pointer`}>
                      {searchBar1 ? (
                        <div
                          onClick={(item) => {
                            dispatch(getComparisonMobileById1(data._id));
                            setSearchBar1("");

                            navigate(`/${item.brandName}/${data._id}`);
                          }}
                        >
                          {data.mobile}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })}
            </div>
          </div>

          {display && (
            <div className="text-white ">
              {searchData.map((item) => (
                <ul key={item._id}>
                  <l1>{searchData.brandName}</l1>
                </ul>
              ))}
              {searchBar1.map((item) => (
                <ul>
                  <Link to={`/phonespec/${item._id}`}>
                    {" "}
                    <li key={item._id}>
                      {item.brandName} {item.modelNumber}
                    </li>
                  </Link>
                </ul>
              ))}
            </div>
          )}
        </div>

        {/* i think it is not being used. will delete soon  */}

        {/* <div
          style={{ zIndex: "-1" }}
          className="rightSideAvatar absolute  hidden lg:block top-0 right-0 "
        ></div> */}
      </div>
    </div>
  );
};

export default Navbar;
