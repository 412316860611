import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { RiFacebookFill, RiLinkedinFill } from "react-icons/ri";
import { ImInstagram, ImTwitter, ImYoutube } from "react-icons/im";

import ImageAsset from "./ImageAsset";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <>
      <footer className="md:flex md:flex-grow items-center pl-2 mb-0 mt-3 text-[#2f2f2f] bg-[#F8F8F8] ">
        <div className=" md:pl-1 pl-7 grid justify-center items-center ">
          {/* <h1 className="flex justify-center font-[400]   "> <img alt="icon" src="../static/media/newlogo.b0c72215337ceecd7aac.png" className="w-40 h-32 inline lg:block cursor-pointer mt-[-.5rem]" /></h1> */}
          <h1 className="flex justify-center font-[400]   ">
            <ImageAsset
              className="w-40 h-32 inline lg:block cursor-pointer mt-[-.5rem]"
              src="newLogo"
            />
          </h1>
          <b className=" ml-auto items-center mr-auto">Mobile Guru</b>
          <p className="mx-auto">Biggest Mobile Selling Websites</p>
          <div className="h-20   flex justify-center items-center ml-10 ">
            <span className="bg-[#FFFFFF] p-2  cursor-pointer rounded-2xl shadow-lg shadow-slate-600 mr-8">
              {" "}
              <a href="https://www.facebook.com/mymobilesguru" target="_blank">
                <RiFacebookFill className="text-green-700 h-4 " />
              </a>
            </span>
            <span className="bg-[#FFFFFF] p-2 cursor-pointer rounded-2xl shadow-lg shadow-slate-600 mr-8">
              {" "}
              <a
                href=" https://www.linkedin.com/in/mymobiles-guru-950796274/"
                target="_blank"
              >
                <RiLinkedinFill className="text-green-700 h-4 " />
              </a>
            </span>
            <span className="bg-[#FFFFFF] p-2 cursor-pointer rounded-2xl shadow-lg shadow-slate-600 mr-8">
              {" "}
              <a
                href=" https://www.instagram.com/my.mobilesguru/"
                target="_blank"
              >
                <ImInstagram className="text-green-700 h-4 " />
              </a>
            </span>
            <span className="bg-[#FFFFFF] p-2  cursor-pointer rounded-2xl shadow-lg shadow-slate-600 mr-8">
              {" "}
              <a
                href="https://www.youtube.com/channel/UCk-qE2_UQ2tPJnsCTwKmi9g"
                target="_blank"
              >
                <ImYoutube className="text-green-700 h-4 " />
              </a>
            </span>
            <span className="bg-[#FFFFFF] p-2  cursor-pointer rounded-2xl shadow-lg shadow-slate-600 mr-8">
              {" "}
              <a href="https://twitter.com/mymobilesguru" target="_blank">
                <ImTwitter className="text-green-700 h-4 " />
              </a>
            </span>
          </div>
          <div className="flex items-center">
            <p className="mx-auto">
              &nbsp;&nbsp;Copyright &copy; 2021 Mobile Guru.
            </p>
          </div>
        </div>
        <div className=" flex flex-grow   text-sm md:text-base justify-between  m-[1em]">
          <li className="mr-5">
            <h2 className="font-bold">Home</h2>
          </li>
          <li className=" ">
            <h2 className="font-bold mb-[1em]">Mobile Details</h2>

            <ul className="mr-2">
              <li>
                <Link to="/reviews ">Reviews</Link>
                {window.scrollTo(0, 0)}
              </li>

              <li>
                <Link to="/news">News</Link>
                {window.scrollTo(0, 0)}
              </li>
            </ul>
          </li>

          <li className="">
            <h2 className="font-bold mb-[1em]">Mobile Details</h2>

            <ul className="mr-2">
              <li
                onClick={() => {
                  navigate("/mobilelist", {
                    state: {
                      isFilter: false,
                    },
                  });
                  {
                    window.scrollTo(0, 0);
                  }
                }}
                className=" cursor-pointer"
              >
                New Mobile
              </li>

              <li>
                <Link to="/advancesearch">Old Mobiles</Link>
                {window.scrollTo(0, 0)}
              </li>
            </ul>
          </li>
          <li className="">
            <ul className="mr-2">
              <li className="mb-[1em]">
                <Link className="font-bold" to="/signin">
                  Others
                </Link>
              </li>

              <li>
                <Link to="/comparison">Comparisons</Link>
                {window.scrollTo(0, 0)}
              </li>

              <li
                onClick={() => {
                  if (
                    localStorage.getItem("phoneNumber") === null ||
                    "" ||
                    undefined
                  ) {
                    navigate("/signin", {
                      state: {
                        isFilter: false,
                      },
                    });
                  } else if (
                    localStorage.getItem("phoneNumber") !== null ||
                    ""
                  ) {
                    navigate("/uploadad");
                    window.history.replaceState(null, null, "/");

                    {
                      window.scrollTo(0, 0);
                    }
                  } else if (
                    localStorage.getItem("phoneNumber") == null ||
                    ""
                  ) {
                    navigate("/signin");
                    window.history.replaceState(null, null, "/");
                  }
                }}
                className=" cursor-pointer"
              >
                Upload Add
              </li>
            </ul>
          </li>
        </div>
      </footer>
      <div className="flex items-center justify-center">
        <p className="">
          powered by:{" "}
          <span
            title="https://iqfun.org/"
            className="hover:underline text-green-700
                   hover:text-green-700
                   visited:text-green-700 cursor-pointer"
          >
            IQfun
          </span>{" "}
          &{" "}
          <span
            title="https://macrosoar.com/"
            className="hover:underline text-green-700
                   hover:text-green-700
                   visited:text-green-700 cursor-pointer"
          >
            Macro Soar
          </span>
        </p>
      </div>
    </>
  );
};

export default Footer;
