import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { metaManagement } from "./api/general-utils";
import { SmartPhoneDetailDesc } from "screens";
import { useParams } from "react-router-dom";
import axios from "axios";
import { baseURL, localbaseURL } from "./api/baseURL";
import { useState } from 'react';
import logo from './newlogo.452377f0ea56bae8d45d.png';

const PublicRoute = ({ path, component, type }) => {
    const data = useParams();
    const [execute, setExecute] = useState(false);

    useEffect(() => {
        let isMounted = true;

        const fetchData = async () => {
            try {
                switch (type) {
                    case 'home':
                        home();
                        break;
                    case 'newMobiles':
                        newMobiles();
                        break;
                    case 'reviewsHome':
                        reviewsHome();
                        break;
                    case 'reviews':
                        reviews();
                        break;
                    case 'newsHome':
                        newsHome();
                        break;
                    case 'news':
                        news();
                        break;
                    case 'blogsHome':
                        blogsHome();
                        break;
                    case 'singleMobile':
                        getSingleMobile();
                        break;
                    default:
                        break;
                }

                if (isMounted) {
                    setExecute(true);
                }
            } catch (error) {
                console.log(error, " errors occuring in sero")
            }
        };

        fetchData();

        return () => {
            // Cancel ongoing asynchronous tasks here
            isMounted = false; // Mark the component as unmounted
        };
    }, [type]);

    const getSingleMobile = async () => {
        try {
            const res = await axios.get(`${baseURL}/api/v1/mobiles/getsinglemobile`, {
                params: {
                    detailId: `${data.id}`,
                },
            });
            if (res && res.data && res.data.data) {
                let title = res.data.data.brandName + " " + res.data.data.modelNumber;
                let desc = res.data.data.description || 'find the latest mobiles on your favourite website mymobilesguru.com';
                let alt = res.data.data.alt || 'mobile'
                let image = res.data.data.mobilePhotos && res.data.data.mobilePhotos.length > 0 && res.data.data.mobilePhotos[0] ? baseURL + res.data.data.mobilePhotos[0] : "1";
                let pageTitle = title
                metaManagement(title, desc, image, window.location.href, pageTitle, alt);
            }
        } catch (error) {
            console.log(error, " errors occuring in sero")
        }
    }

    const home = async () => {
        try {
            let title = 'My Mobiles Guru - Home';
            let desc = 'Uncover the Latest Mobile Prices in Pakistan on My Mobiles Guru!';
            let image = `${localbaseURL}${logo}`;
            let pageTitle = 'My Mobiles Guru - Home';
            let alt = 'mobiles'
            metaManagement(title, desc, image, window.location.href, pageTitle, alt);
        } catch (error) {
            console.log(error, " errors occuring in sero")
        }
    }

    const newMobiles = async () => {
        try {
            let title = 'My Mobiles Guru - New Mobiles';
            let desc = 'Mobile Marvels Unveiled: Explore the Latest in Smartphones on My Mobiles Guru!';
            let image = `${localbaseURL}${logo}`;
            let pageTitle = 'My Mobiles Guru - New Mobiles';
            let alt = 'new mobiles'
            metaManagement(title, desc, image, window.location.href, pageTitle, alt);
        } catch (error) {
            console.log(error, " errors occuring in sero")
        }
    }

    const reviewsHome = async () => {
        try {
            let title = 'My Mobiles Guru - Reviews';
            let desc = 'Discover In-Depth Insights: Get the Newest Smartphone Reviews on My Mobiles Guru!';
            let image = `${localbaseURL}${logo}`;
            let pageTitle = 'My Mobiles Guru - Reviews';
            let alt = 'reviews'
            metaManagement(title, desc, image, window.location.href, pageTitle, alt);
        } catch (error) {
            console.log(error, " errors occuring in sero")
        }
    }

    const reviews = async () => {
        try {
            const res = await axios.get(`${baseURL}/api/v1/review/` + data.id);
            if (res?.data?.brandName && res?.data?.introduction && res?.data?.reviewPhotos) {
                let title = `${res?.data?.brandName} ${res?.data?.mobileName}`;
                let desc = `${res?.data?.introduction}`;
                let image = baseURL + res?.data?.reviewPhotos;
                let alt = res?.data?.alt || `${res?.data?.brandName}`;
                let pageTitle = title
                metaManagement(title, desc, image, window.location.href, pageTitle, alt);
            }

        } catch (err) {
            console.log(err);
        }
    };

    const newsHome = async () => {
        try {
            let title = 'My Mobiles Guru - News';
            let desc = 'Stay Ahead in the Smartphone Game: Explore the Latest News on My Mobiles Guru!';
            let image = `${localbaseURL}${logo}`;
            let pageTitle = 'My Mobiles Guru - News';
            let alt = 'news'
            metaManagement(title, desc, image, window.location.href, pageTitle, alt);
        } catch (error) {
            console.log(error, " errors occuring in sero")
        }
    }

    const news = async () => {
        try {
            const res = await axios.get(`${baseURL}/api/v1/news/` + data.id);
            if (res.data) {
                let title = res?.data?.title;
                let desc = res?.data?.descreption;
                let image = baseURL + res?.data?.image;
                let pageTitle = 'My Mobiles Guru - News';
                let alt = res?.data?.alt || title
                console.log(title, desc, image, window.location.href, pageTitle, alt)
                metaManagement(title, desc, image, window.location.href, pageTitle, alt);
            }
        } catch (err) {
            console.log(err);
        }
    }

    const blogsHome = async () => {
        try {
            let title = 'My Mobiles Guru - Blogs';
            let desc = 'Stay Ahead in the Smartphone Game: Explore the Latest Blogs on My Mobiles Guru!';
            let image = `${localbaseURL}${logo}`;
            let pageTitle = 'My Mobiles Guru - Blogs';
            let alt = 'blogs'
            metaManagement(title, desc, image, window.location.href, pageTitle, alt);
        } catch (error) {
            console.log(error, " errors occuring in sero")
        }
    }
    return execute && component;
}

export default PublicRoute;
