import { baseURL } from "api/baseURL";
import axios from "axios";
import {
  MOBILE_FANS_FAIL,
  MOBILE_FANS_REQUEST,
  MOBILE_FANS_SUCCESS,
  MOBILE_LATEST_REQUEST,
  MOBILE_LATEST_SUCCESS,
} from "redux/Constant/Constant";

export const Latest = () => async (dispatch) => {
  // dispatch({type:MOBILE_LATEST_REQUEST});
  try {
    // const {data} =await axios.get(`${baseURL}/api/v1/mobiles/latest?latest=releaseDate`)
    const { data } = await axios.get(
      `${baseURL}/api/v1/mobiles/latest?latest=releaseDate`
    );
    dispatch({ type: MOBILE_LATEST_SUCCESS, payload: data.success });
  } catch (err) {
    dispatch({ type: MOBILE_LATEST_SUCCESS, payload: err.message });
  }
};

export const Fans = () => async (dispatch) => {
  // dispatch({type:MOBILE_FANS_REQUEST});
  try {
    // const {data} =await axios.get(`${baseURL}/api/v1/mobiles/latest?latest=releaseDate`)
    // const { data } = await axios.get(`${baseURL}/api/v1/mobiles/byfans?fans=totalStars`);
    const { data } = await axios.get(
      `${baseURL}/api/v1/mobiles/byfans?fans=totalStars&order=desc`
    );

    dispatch({ type: MOBILE_FANS_SUCCESS, payload: data.success });
  } catch (err) {
    dispatch({ type: MOBILE_FANS_FAIL, payload: err.message });
  }
};
