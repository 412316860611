export const GADGET_GET_REQUEST = "GADGET_GET_REQUEST";
export const GADGET_GET_SUCCESS = "GADGET_GET_SUCCESS ";
export const GADGET_GET_FAIL = "GADGET_GET_FAIL";

export const GADGET_BYID_REQUEST = "GADGET_BYID_REQUEST";
export const GADGET_BYID_SUCCESS = "GADGET_BYID_SUCCESS";
export const GADGET_BYID_FAIL = "GADGET_BYID_FAIL";

export const GADGET_REVIEW_REQUEST = "GADGET_REVIEW_REQUEST";
export const GADGET_REVIEW_SUCCESS = "GADGET_REVIEW_SUCCUSS";
export const GADGET_REVIEW_FAIL = "GADGET_REVIEW_FAIL";

export const MOBILE_LATEST_REQUEST = "MOBILE_LATEST_REQUEST";
export const MOBILE_LATEST_SUCCESS = "MOBILE_LATEST_SUCCUSS";
export const MOBILE_LATEST_FAIL = "MOBILE_LATEST_FAIL";

export const MOBILE_FANS_REQUEST = "MOBILE_FANS_REQUEST";
export const MOBILE_FANS_SUCCESS = "MOBILE_FANS_SUCCUSS";
export const MOBILE_FANS_FAIL = "MOBILE_FANS_FAIL";
