import React, { useEffect, useState, useContext } from "react";
import axios from "axios";

import { Footer, MobilelistCard, Navbar, MobileBrandsNames } from "components";
import { FaBackward, FaForward } from "react-icons/fa";
import { useNavigate, useLocation } from "react-router";
import { RightSideMenu, LeftSideMenu } from "megaComponents";
import {
  getMobiles,
  getMobilesByPrice,
  getMobilesByRAM,
  getMobilesByROM,
  getMobilesBySize,
  getMobilesByMainCam,
  getMobilesByFrontCam,
  getMobilesByBattery,
  getMobilesByOS,
  getMobilesByBrandName,
  getOldMobiles,
} from "../redux/actions/mobileActions";
import { useDispatch, useSelector } from "react-redux";
import {
  globalNext,
  globalPageNo,
  globalPageNoSetter,
  globalSetNext,
} from "./HomeScreen";

const MobileListView = () => {
  let navigate = useNavigate();
  const [pageNo, setPageNumber] = useState(1);

  //redux
  const dispatch = useDispatch();
  const { allMobiles, moreMobile, allOldMobiles, singleOldMobile, isFilter } =
    useSelector((state) => state.mobileReducer);

  //actions start
  const handleFetchMobiles = (page) => dispatch(getMobiles(page));
  const handleFetchMobilesByPrice = (values, page) =>
    dispatch(getMobilesByPrice(values, page));

  const handleFetchMobilesByRAM = (values, page) =>
    dispatch(getMobilesByRAM(values, page));

  const handleFetchMobilesByROM = (values, page) =>
    dispatch(getMobilesByROM(values, page));
  const handleFetchMobilesBySize = (values, page) =>
    dispatch(getMobilesBySize(values, page));
  const handleFetchMobilesByMainCam = (values, page) =>
    dispatch(getMobilesByMainCam(values, page));

  const handleFetchMobilesByFrontCam = (values, page) =>
    dispatch(getMobilesByFrontCam(values, page));

  const handleFetchMobilesByBattery = (values, page) =>
    dispatch(getMobilesByBattery(values, page));
  const handleFetchMobilesByOS = (values, page) =>
    dispatch(getMobilesByOS(values, page));
  const handleFetchMobilesByBrandName = (values, page) =>
    dispatch(getMobilesByBrandName(values, page));
  const handleFetchOldMobiles = (values, page) =>
    dispatch(getOldMobiles(values, page));

  // adding mobile list/data in state to map over
  const [mobiles, setMobiles] = useState(allMobiles);

  //actions end
  const { state } = useLocation();
  const [filterCheck, setFilterCheck] = useState();

  //useCallBack
  const changeFilter = React.useCallback(() => {
    setFilterCheck(state);
  }, [state]);
  const reduxState = (isFilter) => isFilter;

  //useMemo
  const memoizedValue = React.useMemo(() => reduxState(isFilter), [isFilter]);
  //useEffect
  useEffect(() => {
    document.title = "All Mobiles - mymobilesguru.com";
    setMobiles(allMobiles);
    changeFilter(state);
    if (memoizedValue === true) {
      if (state.title === "Price") {
        document.title = "Mobile Prices - mymobilesguru.com";
        if (globalNext === true) {
          setPageNumber(1);
          globalPageNoSetter(1);
        }
        handleFetchMobilesByPrice(state.item, pageNo);
      }
      if (state.title === "RAM") {
        document.title = "Best RAM Mobiles - mymobilesguru.com";
        if (globalNext === true) {
          setPageNumber(1);
          globalPageNoSetter(1);
        }
        handleFetchMobilesByRAM(state.item, pageNo);
      }
      if (state.title === "Memory") {
        document.title = "Best Mobiles Storage - mymobilesguru.com";
        if (globalNext === true) {
          setPageNumber(1);
          globalPageNoSetter(1);
        }
        handleFetchMobilesByROM(state.item, pageNo);
      }
      if (state.title === "Display") {
        if (globalNext === true) {
          setPageNumber(1);
          globalPageNoSetter(1);
        }
        handleFetchMobilesBySize(state.item, pageNo);
      }
      if (state.title === "Camera") {
        document.title = "Mobile With Best Camera - mymobilesguru.com";
        if (globalNext === true) {
          setPageNumber(1);
          globalPageNoSetter(1);
        }
        handleFetchMobilesByMainCam(state.item, pageNo);
      }
      if (state.title === "Selfi Camera") {
        document.title = "Mobile With Best Selfi Camera - mymobilesguru.com";
        if (globalNext === true) {
          setPageNumber(1);
          globalPageNoSetter(1);
        }
        handleFetchMobilesByFrontCam(state.item, pageNo);
      }
      if (state.title === "Battery") {
        document.title = "Best Battery Mobiles - mymobilesguru.com";
        if (globalNext === true) {
          setPageNumber(1);
          globalPageNoSetter(1);
        }
        handleFetchMobilesByBattery(state.item, pageNo);
      }
      if (state.title === "OS") {
        document.title = "Top Mobiles By OS - mymobilesguru.com";
        if (globalNext === true) {
          setPageNumber(1);
          globalPageNoSetter(1);
        }
        handleFetchMobilesByOS(state.item, pageNo);
      }
      if (state.title === "BrandName") {
        document.title = state.item + " Mobiles - mymobilesguru.com";
        if (globalNext === true) {
          setPageNumber(1);
          globalPageNoSetter(1);
        }
        handleFetchMobilesByBrandName(state.item, pageNo);
      }
      if (state.title === "OldMobiles") {
        document.title = "Old Mobiles - mymobilesguru.com";
        setMobiles(allOldMobiles);

        if (globalNext === true) {
          setPageNumber(1);
          globalPageNoSetter(1);
        }
        handleFetchOldMobiles(state.item, pageNo);
      }

      if (state.title === "company") {
        document.title = "Top 6 Mobiles Companies - mymobilesguru.com";
        if (globalNext === true) {
          setPageNumber(1);
          globalPageNoSetter(1);
        }
        // handleFetchOldMobiles(pageNo);
        handleFetchMobilesByBrandName(state.name, pageNo);
      }

      console.log("calling", globalPageNo);

      handleFetchMobiles(globalPageNo);
    }
  }, [pageNo, memoizedValue, filterCheck, changeFilter]);
  console.log("more===>", allMobiles);
  console.log("currently in mobiles", mobiles);
  console.log("here===>>>", singleOldMobile);
  // here trying to get mobiles directly because currently redu/state managment of this application is not working and is confeusing so doing bypass and using use effect directly
  const baseURL = "https://backend.mymobilesguru.com";
  const [newMobiles, setNewMobiles] = useState(mobiles);
  useEffect(() => {
    const callApi = async () => {
      if (state.title === "BrandName") {
        const res = await axios.get(
          `${baseURL}/api/v1/mobiles/filterbybrandname?page=1&brandName=${state.item}`
        );
        if (res.data.success===true){ setNewMobiles(res.data.data); }
      } else if (state.title === "Price") {
        const res = await axios.get(
          `${baseURL}/api/v1/mobiles/filterbyram?page=1&lP=${state.item.lP}&uP=${state.item.uP}`
        );

        if (res.data.success===true){ setNewMobiles(res.data.data); }
      } else if (state.title === "RAM") {
        const res = await axios.get(
          `${baseURL}/api/v1/mobiles/filterbyram?page=1&lP=${state.item.lP}&uP=${state.item.uP}`
        );

        if (res.data.success===true){ setNewMobiles(res.data.data); }
      } else if (state.title === "Memory") {
        const res = await axios.get(
          `${baseURL}/api/v1/mobiles/filterbyrom?page=1&lP=${state.item.lP}&uP=${state.item.uP}`
        );

        if (res.data.success===true){ setNewMobiles(res.data.data); }
      } else if (state.title === "Display") {
        const res = await axios.get(
          `${baseURL}/api/v1/mobiles/filterbyscreensize?page=1&lP=${state.item.lP}&uP=${state.item.uP}`
        );

        if (res.data.success===true){ setNewMobiles(res.data.data); }
      } else if (state.title === "Camera") {
        const res = await axios.get(
          `${baseURL}/api/v1/mobiles/filterbymaincamera?page=1&lP=${state.item.lP}&uP=${state.item.uP}`
        );

        if (res.data.success===true){ setNewMobiles(res.data.data); }
      } else if (state.title === "Selfi Camera") {
        const res = await axios.get(
          `${baseURL}/api/v1/mobiles/filterbyfrontcamera?page=1&lP=${state.item.lP}&uP=${state.item.uP}`
        );

        if (res.data.success===true){ setNewMobiles(res.data.data); }
      } else if (state.title === "Battery") {
        const res = await axios.get(
          `${baseURL}/api/v1/mobiles/filterbybattery?page=1&lP=${state.item.lP}&uP=${state.item.uP}`
        );

        if (res.data.success===true){ setNewMobiles(res.data.data); }
      } else if (state.title === "OS") {
        const res = await axios.get(
          `${baseURL}/api/v1/mobiles/filterbyos?page=1&OS=${state.item.value}`
        );
        
          
        if (res.data.success===true){ setNewMobiles(res.data.data); }
        
      } else {
        setNewMobiles(mobiles);
      }
    };
    callApi();
  }, [state.item]);
  // console.log("Now is ", state.item.value, "and", );
  return (
    <React.Fragment>
      <Navbar />
      <div className="mainContent mt-8 flex flex-col lg:flex-row lg:px-5">
        <div className="lg:w-[20%] order-2 px-5 lg:px-0">
          <MobileBrandsNames />
          <LeftSideMenu />
        </div>
        {/* cards  */}
        <div className="lg:w-[60%] h-full  pb-0 lg:pb-[20rem]  flex flex-col mt-[-2rem] lg:mt-0 lg:ml-2 lg:mr-[.2rem]   order-1 lg:order-2  ">
          <div className="flex mb-1 text-md pl-5 mt-8 pr-5 lg:pl-0 lg:pr-0 font-semibold ">
            <h3 className="mr-auto ">
              {state.title === "OldMobiles" ? "Old Mobiles" : "New Mobiles"}
            </h3>
            <h3
              className="ml-auto cursor-pointer pr-2"
              onClick={() => {
                navigate("/uploadad");
              }}
            >
              Sell Your Mobile
            </h3>
          </div>
          <div className="lg:pl-4 lg:pr-4 rounded-md bg-center bg-[#D2DCE7] ">
            <div className="flex-1  p-4 pl-1 pr-1 ">
              {newMobiles[0]?.brandName}
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3  lg:grid-cols-3 gap-2 gap-y-6">
                {newMobiles !== undefined
                  ? newMobiles.map((item, _id) => {
                      return <MobilelistCard item={item} key={_id} />;
                    })
                  : mobiles.map((item, _id) => {
                      return <MobilelistCard item={item} key={_id} />;
                    })}
              </div>
            </div>
          </div>
          {state.title === "advance" ? null : (
            <div className="flex items-center justify-between my-4">
              <div>
                <div
                  onClick={() => {
                    globalSetNext(false);
                    setPageNumber((prev) => prev - 1);
                    // handleFetchMobiles(globalPageNo - 1);
                    globalPageNoSetter(globalPageNo - 1);
                  }}
                  className={`cursor-pointer flex items-center justify-start gap-3 py-2 rounded-xl px-4 border-2 bg-green-700 text-white ${
                    globalPageNo == 1 ? "hidden" : ""
                  }`}
                >
                  <FaBackward />
                  <h2>Previous</h2>
                </div>
              </div>
              <div
                onClick={() => {
                  globalSetNext(false);
                  setPageNumber((prev) => prev + 1);
                  // handleFetchMobiles(globalPageNo + 1);
                  globalPageNoSetter(globalPageNo + 1);
                }}
                className={`cursor-pointer flex mr-[1rem] items-center justify-start gap-3 py-2 rounded-xl px-4 border-2 bg-green-700 text-white ${
                  moreMobile ? "" : "hidden"
                }`}
              >
                <h2>Next</h2>
                <FaForward />
              </div>
            </div>
          )}
        </div>
        <div className="lg:w-[20%] px-5 lg:px-0  text-center order-1 lg:order-3 lg:absolute top-3 right-0">
          <div className="flex flex-col">
            <button
              className="w-auto border-2 bg-transparent p-1 mt-5 lg:mt-0  rounded-[1rem]"
              onClick={() => navigate("/advancesearch")}
            >
              Advance Search
            </button>
            <button
              className="w-auto border-2 bg-transparent mt-8 p-1  rounded-[1rem]"
              onClick={() => navigate("/reviews")}
            >
              Videos Reviews
            </button>
            <button
              className="w-auto text-white   bg-[#FF8C00] font-semibold text-md mt-3 p-1 pl-[2rem] pr-[2rem] rounded-[1rem]"
              onClick={() => navigate("/uploadad")}
            >
              Buy And Sell <br /> Smart Phone
            </button>
          </div>
          <RightSideMenu />
        </div>
      </div>
      <div className="mt-[10rem]">
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default MobileListView;
