import { RightSideMenu } from "megaComponents";
import { useNavigate, useParams } from "react-router";
import React from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { baseURL } from "api/baseURL";
import HelmetComponent from "./HelmetComponent";

const NewsDetails = () => {
  // const = ;
  // if (location.pathname !== prevProps.location.pathname) {

  // }
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.pathname.split("/")[3];
  const [data, setData] = useState([]);
  useEffect(() => {
    const GetReview = async () => {
      try {
        //  const res = await axios.get("http://localhost:8080/api/v1/news/"+id);
        const res = await axios.get(`${baseURL}/api/v1/news/` + id);
        setData(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    GetReview();
  }, [location]);

  // const PF = "http://localhost:8080/images/";
  const PF = `${baseURL}`;

  return (
    <React.Fragment>
      <Navbar />
      {/* <HelmetComponent
        title={data.title + "mymobilesguru.com"}
        description={data.title}
        image="https://play-lh.googleusercontent.com/uAu69C99ameBLdlE1Iv2ypibSqwQ3lqQGqm0NzcR1XpQ5gjBfNfW2sotbhfg2hPSjGZ3"
      /> */}
      <div className="mainContent mt-20 flex flex-col lg:flex-row px-1 lg:px-10">
        <div className="lg:w-[20%]  ">
          <div className="flex flex-col">
            <button
              className="w-auto border-2 bg-transparent p-1 mt-5 lg:mt-0  rounded-[1rem]"
              onClick={() => navigate("/advancesearch")}
            >
              Advance Search
            </button>
            <button
              className="w-auto border-2 bg-transparent mt-8 p-1  rounded-[1rem]"
              onClick={() => navigate("/reviews")}
            >
              Videos Reviews
            </button>
            <button
              className="w-auto text-white   bg-[#FF8C00] font-semibold text-md mt-3 p-1 pl-[2rem] pr-[2rem] rounded-[1rem]"
              onClick={() => navigate("/uploadad")}
            >
              Buy And Sell <br /> Smart Phone
            </button>
          </div>

          <RightSideMenu />
        </div>
        <div className="w-full lg:w-[80%] order-1 h-full  pb-0 lg:pb-[20rem]  flex flex-col lg:pl-7 lg:pr-7 mt-[-2rem]  lg:ml-3 lg:mr-3 lg:mt-0  lg:order-2 bg-center ">
          <section className="text-gray-700 body-font overflow-hidden bg-white">
            <div className="container px-2 py-0 mx-auto">
              <div className="lg:w-5/5 mx-6 flex flex-col flex-wrap relative sm:space-y-4 ">
                <div className="">
                  <img
                    className="lg:w-[100%] w-full object-contain object-center rounded border border-gray-200 h-80"
                    src={PF + data.image }
                    alt={data.alt}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = `${window.location.origin}/static/media/newlogo.b0c72215337ceecd7aac.png`;
                    }}
                  />
                  {/* <div className='absolute top-[13rem] w-full bg-[#07844c] md:mb-8 sm:mb-8 font-bold text-[white]' style={{opacity:'.3'}} > */}
                  {/* <div className='fon'> */}
                  <h2 className="text-white p-[.7rem] w-full text-center  text-3xl font-serif drop-shadow-2xl font-nomal mb-1 absolute -translate-y-[120%] bg-opacity-20 bg-[#000]">
                    {data.title}
                  </h2>
                </div>
                {/* </div> */}
                <div className=" w-full lg:py-6 mt-6 lg:mt-10 border-2 shadow-xl">
                  {/* <h2 className="text-gray-900 text-3xl title-font font-medium mb-1">{data.title}</h2> */}
                  <div className="flex mb-4"></div>
                  <div className="flex flex-col items-center mb-6 ">
                    <h1 className="text-gray-900 text-3xl title-font font-bold mb-2 font-sans ">
                      DESCRIPTION
                    </h1>
                    <div className="h-[4px] w-[150px] bg-green-500 rounded-sm" />
                  </div>
                  {/* <p className="leading-relaxed"> {data.descreption} </p> */}
                  <div
                    className="leading-relaxed max-w-[700px] mx-auto text-lg"
                    dangerouslySetInnerHTML={{ __html: data.descreption }}
                  />
                </div>
              </div>
            </div>
          </section>
          {data.details != undefined && data.details.length > 0 ? (
            <section className="text-gray-700 body-font overflow-hidden bg-white w-full  ">
              <div className="container px-2 py-2 mx-auto">
                <div className="lg:w-5/5 mx-6 flex flex-wrap">
                  <div className=" w-full  lg:py-6 mt-6 lg:mt-2 border-2 shadow-xl ">
                    <div className="flex flex-col items-center mb-6 ">
                      <h1 className="text-gray-900 text-3xl title-font font-bold mb-1 font-sans  ">
                        DETAILS
                      </h1>
                      <div className="h-[4px] w-[100px] bg-green-500 rounded-sm" />
                    </div>
                    {/* <p className="leading-relaxed pr-4"> {data.details } 
       
        </p> */}
                    <div
                      className="leading-relaxed max-w-[700px] mx-auto  text-lg "
                      dangerouslySetInnerHTML={{ __html: data.details }}
                    />
                  </div>
                  {/* <iframe width="50%" className='mt-3 lg:pl-10'  src="https://www.youtube.com/embed/SCSdb-vObNA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                </div>
              </div>
            </section>
          ) : (
            ""
          )}
        </div>
      </div>

      {/* <Footer /> */}
      <div className="mt-[10rem]">
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default NewsDetails;
