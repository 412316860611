import React from "react";
import { FaStar } from "react-icons/fa";
import { baseURL } from "api/baseURL";
import { useNavigate } from "react-router";
import { getSingleMobile } from "../redux/actions/mobileActions";
import { getMobileById } from "../redux/actions/mobileActions";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { CleaningServices } from "@mui/icons-material";

const MobilelistCard = (props) => {
  function currencyFormat(num) {
    return num?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
  const {
    brandName,
    mobilePhotos,
    mobilename,
    price,
    rating,
    modelNumber,
    memory,
    ram,
    rom,
    alt,
  } = props.item;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSetSingleMobile = (value) => dispatch(getMobileById(value));
  const handleFetchUsedMobileById = (value) => dispatch(getSingleMobile(value));

  const total = props.item.new
    ? props.item.reviews.length !== 0
      ? props.item.reviews.reduce(
          //reduce go through the array and cartItem is the each item in the array
          (accumulatedTotal, review) =>
            accumulatedTotal + parseInt(review.stars),
          0 //0 is the start point of accumulatedTotal
        )
      : 1
    : 0;
  const averageReview = props.item.new
    ? props.item.reviews.length !== 0
      ? total / props.item.reviews.length
      : 0
    : 0;
  const stars = Array(averageReview > 1 ? Math.floor(averageReview) : 1).fill(
    0
  );
  let str = `/${props.item.brandName}-${props.item.modelNumber}`;
  return (
    <React.Fragment>
      {props.item.new !== true ? (
        <Link to={`${str.replace(/\s/g, "-")}/${props.item._id}`}>
          <div
            className="bg-white flex-col w-full  rounded-md pb-5 cursor-pointer"
            onClick={() => {
              // let str = `/${props.item.brandName}-${props.item.modelNumber}`;
              // navigate(`${str.replace(/\s/g, '-')}/${props.item._id}`, {
              //   state: props.item,
              // });

              window.scrollTo(0, 0);
            }}
          >
            <div className="flex justify-center">
              {/* <Link to={`/${props.item.brandName}-${props.item.modelNumber}/${props.item._id}`}> */}
              <img
                className=" object-top px-1 py-1 xs:p-2  h-[8rem] sm:h-[14rem]"
                src={mobilePhotos[0] ? `${baseURL}${mobilePhotos[0]}` : ""} about={alt}
                // src="https://th.bing.com/th/id/OIP.Az-eKGvrpD23Kp6n6XdSDQHaFj?pid=ImgDet&w=1600&h=1200&rs=1"
                alt={alt}
              />
              {/* </Link> */}
            </div>

            <div className="flex-col w-full px-3 capitalize">
              <h3>
                {brandName} {mobilename} {modelNumber}
              </h3>
              <div className="flex">
                <p className="text-md font-bold ">
                  {/* currencyFormat(singleMobile.priceInPKR) */}
                  Price (PKR): {currencyFormat(props.item.priceInPKR)}{" "}
                </p>
              </div>
              <div className="flex">
                <p className="text-md font-bold ">
                  Price (USD): ${currencyFormat(props.item.priceInUSD)}{" "}
                </p>
              </div>

              <p className="text-xs mt-2 font-light">
                {memory?.ram.value.join(",")} {memory?.ram.unit} /{" "}
                {memory?.rom.value.join(",")} {memory?.rom.unit}
              </p>
              <div className="flex w-full items-center">
                <div className="flex items-center text-[#F4871E] text-xs">
                  {props.item.reviews?.length === 0
                    ? "Not Rated Yet"
                    : stars.map((_, index) => {
                        return <FaStar key={index} />;
                      })}
                  <div className="flex items-center text-[#F4871E] text-xs pl-2 text-center">
                    {averageReview < 1
                      ? null
                      : Math.round(averageReview * 10) / 10}
                  </div>
                </div>
                <h3 className="text-xs ml-3 ">{rating}</h3>{" "}
                {/*  <button className="flex ml-3 items-center rounded-[0.3rem] p-1  text-xs border border-[#9DC2FF] text-[#9DC2FF] ">
            <ImageAsset
              className="w-[1rem] h-[1rem] mr-1  "
              src="HeartIcon"
            />
            Watch
          </button> */}
              </div>
            </div>
          </div>
        </Link>
      ) : (
        <div
          className="bg-white flex-col w-full  rounded-md pb-5 cursor-pointer"
          onClick={() => {
            localStorage.setItem("detailId", props.item._id);
            handleFetchUsedMobileById(props.item);
            navigate(
              `/phonedetail/${props.item._id}`
              // , {
              // state: props.item,
              // }
            );
            window.scrollTo(0, 0);
          }}
        >
          <div className="flex justify-center">
            <img
              className=" object-top p-2  h-[14rem]"
              src={`${baseURL}${props.item.usedMobilePhoto}`}
              alt={`${props.item?.alt}`}
            />
          </div>
          <div className="flex-col w-full pl-6">
            <h3>{props.item.brandName}</h3>
            <h3 className="text-[#F4871E] font-bold text-2xl mt-2">{price}</h3>
            <p className="text-xs mt-2 font-light">
              RAM: {ram[0]} / ROM: {rom[0]}
            </p>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default MobilelistCard;
