import React from "react";
import { Footer, Navbar, NewsCard } from "components";
import { RightSideMenu } from "megaComponents";
import { useNavigate } from "react-router";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { baseURL } from "api/baseURL";
import HelmetComponent from "components/HelmetComponent";
import BlogsCard from "components/BlogsCard";

const BlogPage = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  useEffect(() => {
    const GetBlogs = async () => {
      try {
        const res = await axios.get(`${baseURL}/api/v1/blogs/getAllBlogs`);
        setData(res.data.data);
      } catch (err) {
        console.log(err);
      }
    };
    GetBlogs();
  }, []);

  return (
    <React.Fragment>
      <Navbar />
      <HelmetComponent
      
        title="Blog Page - mymobilesguru.com"
        description="Blog Page - Description"
        image="https://picsum.photos/300"
      />

      <div className="mainContent mt-8 flex flex-col lg:flex-row px-1 lg:px-10">
        <div className="lg:w-[20%] order-2 ">
          <div className="flex flex-col">
            <button
              className="w-auto border-2 bg-transparent p-1 mt-5 lg:mt-0  rounded-[1rem]"
              onClick={() => navigate("/advancesearch")}
            >
              Advance Search
            </button>
            <button
              className="w-auto border-2 bg-transparent mt-8 p-1  rounded-[1rem]"
              onClick={() => navigate("/reviews")}
            >
              Videos Reviews
            </button>
            <button
              className="w-auto text-white   bg-[#FF8C00] font-semibold text-md mt-3 p-1 pl-[2rem] pr-[2rem] rounded-[1rem]"
              onClick={() => navigate("/uploadad")}
            >
              Buy And Sell <br /> Smart Phone
            </button>
          </div>

          <RightSideMenu />
        </div>

        <div className="w-full lg:w-[70%] order-1 h-full  pb-0 lg:pb-[20rem]  flex flex-col lg:pl-7 lg:pr-7 mt-[1rem]  lg:ml-3 lg:mr-3 lg:mt-0  lg:order-2 bg-center ">
          <div className="flex-col">
            <div className="video w-full lg:w-full h-[10rem] rounded-3xl bg-cover lg:bg-cover  bg-no-repeat  bg-[url('assets/images/newsBanner.png')]">
              <h1 className="p-10  lg:p-12 lg:pl-16  text-center  text-2xl lg:text-5xl font-bold text-white">
                Blogs
              </h1>
            </div>
          </div>
          {/* cards */}

          <div className="mt-6">
            {data?.map((blog) => {
              return (
               <BlogsCard
               key={blog._id}
               imgsrc={blog.image}
               alt={blog.alt}
               title={blog.title}
               desc={blog.descreption}
               date={blog.date}
               id={blog._id}
               />
              );
            })}
          </div>
        </div>
      </div>
      <div className="mt-[10rem]">
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default BlogPage;
