import React from "react";
import { ImageAsset } from "components";
import { Link } from "react-router-dom";
import { baseURL } from "api/baseURL";
import axios from "axios";

const ReviewCard = (props) => {
  const { id, imgsrc,alt, title, desc, date } = props;
  // const PF = "http://localhost:8080/images/";
  const PF = `${baseURL}`;

  return (
    <React.Fragment>
      <div className="flex-col w-full shadow-md md:shadow-2xl pb-3 bg-[#fff] relative border-[3px] rounded-[20px]">
        {/* <Link to={`/review/detail/${id}/${title.replace(/\s/g, '-')}`}> */}
        <Link to={`/review/${title.replace(/\s/g, "-")}/${id}`}>
          {/* <ImageAsset className="w-full  h-[10rem] " src={PF+imgsrc} /> */}
          <img
            className="w-full  h-[15rem] object-contain "

            src={PF + imgsrc}
            alt={alt}
          />
        </Link>
        <div className="pl-3 bg-[#000] bg-opacity-20 absolute bottom-8 w-full text-white ">
          {/* <Link to={`/review/detail/${id}/${title.replace(/\s/g, '-')}`}> */}
          <Link to={`/review/${title.replace(/\s/g, "-")}/${id}`}>
            {/* <h1 className="mt-2 text-2xl  font-bold hover:underline"> */}
            <div
              className="leading-relaxed text-white pr-4 font-bold font-serif text-2xl drop-shadow-2xl overflow-hidden"
              dangerouslySetInnerHTML={{ __html: title }}
            />
            {/* {title} */}
            {/* </h1> */}
          </Link>
          <h3 className="mt-3 text-xs overflow-hidden ">
            {/* <div className="leading-relaxed pr-4 text-xl font-medium font-mono" dangerouslySetInnerHTML={{__html: desc }} /> */}
            {/* {desc} */}
          </h3>
        </div>
        <div className="w-full text-left text-xs mt-5 pr-10 pl-2">
          <label htmlFor=""> {date}</label>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ReviewCard;
