import { Outlet } from "react-router";
import React, { useState, createContext } from "react";
import "./App.css";
import { Provider } from "react-redux";
import store from "./redux/store";
import HttpsRedirect from "react-https-redirect";

const App = () => {
  return (
    <Provider store={store}>
      <HttpsRedirect>
        <div>
          <Outlet />
        </div>
      </HttpsRedirect>
    </Provider>
  );
};

export default App;
