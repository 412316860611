import { Navbar, Footer } from "components";
import HelmetComponent from "components/HelmetComponent";
import { RightSideMenu, LeftSideMenu } from "megaComponents";
import { MobileBrandsNames } from "components";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { baseURL } from "api/baseURL";
import { FaStar } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { GadgetGet } from "redux/actions/GadgetAction";

function GadgetList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const gadgetList = useSelector((store) => store.gadget);
  // const [gadget, setGadget] = useState([]);
  const { loading, error, gadget } = gadgetList;
  useEffect(() => {
    dispatch(GadgetGet());
  }, [dispatch]);
  useEffect(() => {
    const getProfile = async () => {
      try {
        const { data } = await axios.post(
          "https://diagnosis-plus-dev.herokuapp.com/api/v1/technician/login",
          {
            email: "jawad1@gmail.com",
            password: "dummyPass",
          }
        );
      } catch (error) {
        console.log(error);
      }
    };

    getProfile();
  });
  const PF = `${baseURL}`;

  return (
    <>
      <Navbar />
      {/* <HelmetComponent
        title="Gadget List - mymobilesguru.com"
        description="Gadget List - Description"
        image="https://picsum.photos/300"
      /> */}

      <div className="mainContent  md:space-x-2   lg:mt-7 flex flex-col lg:flex-row px-3  ">
        <div className="mt-5 lg:w-[20%] hidden lg:block">
          {" "}
          <MobileBrandsNames /> <LeftSideMenu />{" "}
        </div>

        <div className="mainContent bg-[#D2DCE7] lg:w-[59%]  m-1 p-1 rounded-md mt-5  ">
          {loading ? (
            // <h1 className=' text-3xl bg-white font-serif font-bold animate-bounce p-6 text-black text-center '>Looding</h1> :
            " "
          ) : (
            <div className="lg:pl-1 lg:pr-1 rounded-md bg-center bg-[#D2DCE7] ">
              <div className="flex-1  p-2 pl-1 pr-1 ">
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3  lg:grid-cols-3 gap-2 gap-y-6">
                  {gadget.map((items) => (
                    <Link to={"/gadget/" + items._id}>
                      <div
                        // onClick={() => {
                        //   navigate(`/gadget/  ${items._id}`);
                        // }}
                        className="bg-white flex-col w-full  rounded-md pb-5 cursor-pointer"
                      >
                        <div className="flex justify-center">
                          <img
                            className=" object-top px-1 py-1 xs:p-2  h-[8rem] sm:h-[14rem]"
                            src={PF + items.photo[0]}
                            alt={items.alt}
                          />
                        </div>

                        <div className="flex-col w-full pl-6 capitalize">
                          <h3>{items.Name}</h3>
                          <div className="flex">
                            <p className=" md:text-base text-sm   mr-1 font-bold ">
                              Price:
                            </p>
                            <p className="text-sm ">{items.price}</p>
                          </div>
                          <div className="flex">
                            <p className="md:text-base text-sm mr-1 font-bold ">
                              Maker:
                            </p>
                            <p className="text-sm ">{items.maker}</p>
                          </div>
                          <div className="flex">
                            <p className=" md:text-base text-sm mr-1 font-bold ">
                              Model:
                            </p>
                            <p className="text-sm ">{items.model}</p>
                          </div>
                          <div className="md:flex">
                            <p className="md:text-base text-sm  mr-1  font-bold ">
                              Release Date:
                            </p>
                            <p className="text-sm ">{items.releaseDate}</p>
                          </div>
                          <div className="flex w-full items-center ">
                            <h1 className="flex  item-center">
                              {" "}
                              <span className="text-2xl font-bold"> </span>{" "}
                              {items.stars == 1 ? (
                                <p className="flex items-center mt-1">
                                  <FaStar className="text-[#F4871E] text-[12px] " />{" "}
                                </p>
                              ) : items.stars == 2 ? (
                                <p className="flex items-center mt-1 ">
                                  <FaStar className="text-[#F4871E] text-[12px]" />
                                  <FaStar className="text-[#F4871E] text-[12px]" />
                                </p>
                              ) : items.stars == 3 ? (
                                <p className="flex items-center mt-1">
                                  <FaStar className="text-[#F4871E] text-[12px]" />
                                  <FaStar className="text-[#F4871E] text-[12px]" />
                                  <FaStar className="text-[#F4871E] text-[12px]" />
                                </p>
                              ) : items.stars == 4 ? (
                                <p className="flex items-center mt-1">
                                  <FaStar className="text-[#F4871E] text-[12px]" />
                                  <FaStar className="text-[#F4871E] text-[12px]" />
                                  <FaStar className="text-[#F4871E] text-[12px]" />
                                  <FaStar className="text-[#F4871E] text-[12px]" />
                                </p>
                              ) : items.stars == 5 ? (
                                <p className="flex items-center mt-1">
                                  <FaStar className="text-[#F4871E] text-[12px]" />
                                  <FaStar className="text-[#F4871E] text-[12px]" />
                                  <FaStar className="text-[#F4871E] text-[12px]" />
                                  <FaStar className="text-[#F4871E] text-[12px]" />
                                  <FaStar className="text-[#F4871E] text-[12px]" />
                                </p>
                              ) : (
                                <p className=" text-[#F4871E] text-[12px] ">
                                  Not Rated yet
                                </p>
                              )}{" "}
                            </h1>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="lg:w-[20%]  text-center order-2  lg:absolute lg:order-3  top-3 right-0">
          <div className="flex flex-col">
            <button
              className="w-auto cursor-pointer border-2 bg-transparent p-1 mt-5 lg:mt-0  rounded-[1rem]"
              onClick={() => navigate("/advancesearch")}
            >
              Advance Search
            </button>
            <button
              className="w-auto cu border-2 bg-transparent mt-8 p-1  rounded-[1rem]"
              onClick={() => navigate("/reviews")}
            >
              Videos Reviews
            </button>
            <button
              className="w-auto text-white  bg-[#F4871E] font-semibold  text-md mt-3 p-1 pl-[2rem] pr-[2rem] rounded-[1rem]"
              onClick={() => navigate("/uploadad")}
            >
              Buy And Sell <br /> Smart Phone
            </button>
          </div>
          <RightSideMenu />

          <div className="mt-5 lg:hidden ">
            {" "}
            <MobileBrandsNames /> <LeftSideMenu />{" "}
          </div>
        </div>
      </div>
      {gadget.length < 20 && <div className="lg:mb-[46%] border-2  "></div>}
      <div className="  w-full">
        <Footer />
      </div>
    </>
  );
}

export default GadgetList;
