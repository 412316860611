import React, { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import ReactDOM from "react-dom";
import { hydrate, render } from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HttpsRedirect from "react-https-redirect";

import {
  HomeScreen,
  SmartPhoneDetailDesc,
  UsedSmartPhoneDetails,
  UsedMobileUploadAdDetails,
  SmartPhoneDetailSpec,
  MobileListView,
  VideoReview,
  News,
  Reviews,
  ContactUs,
  MobileAcessories,
  Comparison,
  AdvanceSearch,
  Auth,
} from "screens";
import SignIn from "components/SignIn";
import ReviewDetail from "components/ReviewDetail";
import NewsDetails from "components/NewsDetails";
import GadgetList from "screens/GadgetList";
import Gadget from "screens/Gadget";
import TopCompanies from "screens/TopCompanies";
import TopFans from "screens/TopFans";
import LatestPhones1 from "screens/LatestMobile1";
import BlogPage from "screens/BlogPage";
import PublicRoute from "./publicRoute.js";
import BlogDetails from "components/BlogDetails";

const APP = (
  <React.StrictMode>
    <HttpsRedirect>
      <Router>
        <Routes>
          <Route path="/" element={<App />}>
            <Route index element={<PublicRoute type='home' component={<HomeScreen />}></PublicRoute>} />
            <Route path="/advancesearch" element={<AdvanceSearch />} />
            <Route
              path="/:phonespec/:id/phonedescription"
              element={
                <SmartPhoneDetailDesc />
              }
            />
            <Route
              path="/phonedetails/:id"
              element={<UsedSmartPhoneDetails />}
            />
            <Route path="/uploadad" element={<UsedMobileUploadAdDetails />} />
            <Route path="/phonespec" element={<PublicRoute type='singleMobile' component={<SmartPhoneDetailSpec />}></PublicRoute>} />
            <Route
              exact
              path="/:phonespec/:id"
              element={<PublicRoute type='singleMobile' component={<SmartPhoneDetailSpec />} />}
            />
            <Route
              exact
              path="/:deviceName"
              element={<SmartPhoneDetailSpec />}
            />
            <Route path="/mobilelist" element={
              <PublicRoute type='newMobiles' component={<MobileListView />}></PublicRoute>
            } />
            <Route path="/videoreview" element={<VideoReview />} />
            <Route path="/news" element={
              <PublicRoute type='newsHome' component={<News />}></PublicRoute>
            } />
            <Route path="/blogs" element={<PublicRoute type='blogsHome' component={<BlogPage />}></PublicRoute>} />
            <Route path="/reviews" element={
              <PublicRoute type='reviewsHome' component={<Reviews />}></PublicRoute>
            } />
            <Route path="/review/:detail/:id" element={<PublicRoute type='reviews' component={<ReviewDetail />}></PublicRoute>} />
            <Route path="/news/:detail/:id" element={<PublicRoute type="news" component={<NewsDetails />}></PublicRoute>} />
            <Route path="/blogs/:detail/:id" element={<PublicRoute type="blogs" component={<BlogDetails />}></PublicRoute>} />

            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/acessories" element={<MobileAcessories />} />
            <Route path="/comparison" element={<Comparison />} />
            <Route path="/Auth" element={<Auth />} />
            <Route path="/ignin" element={<SignIn />} />
            <Route path="/gadgetList" element={<GadgetList />} />
            <Route path="/gadget/:id" element={<Gadget />} />
            <Route path="/TopCompanies" element={<TopCompanies />} />
            <Route path="/TopFans" element={<TopFans />} />
            <Route path="/LatestMobile" element={<LatestPhones1 />} />
          </Route>
        </Routes>
      </Router>
    </HttpsRedirect>
  </React.StrictMode>
);

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(APP, rootElement);
} else {
  render(APP, rootElement);
}
