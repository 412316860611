export const actionTypes = {
  GET_ALL_UNPAGINATED_MOBILES: "GET_ALL-GET_ALL_UNPAGINATED_MOBILES",
  GET_MOBILES: "GET_MOBILES",
  GET_SINGLE_MOBILE: "GET_SINGLE_MOBILE",
  GET_OLD_MOBILES: "GET_OLD_MOBILES",
  GET_OLD_SINGLE_MOBILE: "GET_OLD_SINGLE_MOBILE",
  GET_MOBILE_BY_ID: "GET_MOBILE_BY_ID",
  GET_LATEST_MOBILES: "GET_LATEST_MOBILES",
  MORE_MOBILES: "MORE_MOBILES",
  IS_FILTER: "IS_FILTER",

  FILTER_BY_RAM: "FILTER_BY_RAM",
  FILTER_BY_ROM: "FILTER_BY_ROM",
  FILTER_BY_PRICE: "FILTER_BY_PRICE",
  FILTER_BY_SCREEN_SIZE: "FILTER_BY_SCREEN_SIZE",
  FILTER_BY_OS: "FILTER_BY_OS",
  FILTER_BY_MAIN_CAMERA: "FILTER_BY_MAIN_CAMERA",
  FILTER_BY_FRONT_CAMERA: "FILTER_BY_FRONT_CAMERA",
  FILTER_BY_BATTERY: "FILTER_BY_BATTERY",
  FILTER_BY_LATEST_ADDED: "FILTER_BY_LATEST_ADDED",
  FILTER_BY_POPULARITY: "FILTER_BY_POPULARITY",

  GET_ALL_MOBILES_FOR_COMPARISON: "GET_ALL_MOBILES_FOR_COMPARISON",
  FIRST_COMPARISON_MOBILE: "FIRST_COMPARISON_MOBILE",
  SECOND_COMPARISON_MOBILE: "SECOND_COMPARISON_MOBILE",
};
