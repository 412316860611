import { RightSideMenu } from "megaComponents";
import { useNavigate } from "react-router";
import React from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { baseURL } from "api/baseURL";
import { Link, useLocation } from "react-router-dom";
import ReactPlayer from "react-player";
import { FaStar } from "react-icons/fa";

const ReviewDetail = () => {
  window.scrollTo(0, 100);
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.pathname.split("/")[3];
  const [data, setData] = useState([]);
  const stars = Array(data.rating > 1 ? Math.floor(data.rating) : 1).fill(0);
  useEffect(() => {
    const GetReview = async () => {
      try {
        //  const res = await axios.get("http://localhost:8080/api/v1/review/"+id);
        const res = await axios.get(`${baseURL}/api/v1/review/` + id);
        setData(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    GetReview();
  }, []);

  // const PF = "http://localhost:8080/images/";
  const PF = `${baseURL}`;

  return (
    <React.Fragment>
      <Navbar />
      <div className="mainContent mt-20 flex flex-col lg:flex-row px-1 lg:px-10">
        <div className="lg:w-[20%]  ">
          <div className="flex flex-col">
            <button
              className="w-auto border-2 bg-transparent p-1 mt-5 lg:mt-0  rounded-[1rem]"
              onClick={() => navigate("/advancesearch")}
            >
              Advance Search
            </button>
            <button
              className="w-auto border-2 bg-transparent mt-8 p-1  rounded-[1rem]"
              onClick={() => navigate("/reviews")}
            >
              Videos Reviews
            </button>
            <button
              className="w-auto text-white   bg-[#FF8C00] font-semibold text-md mt-3 p-1 pl-[2rem] pr-[2rem] rounded-[1rem]"
              onClick={() => navigate("/uploadad")}
            >
              Buy And Sell <br /> Smart Phone
            </button>
          </div>

          <RightSideMenu />
        </div>
        <div className="w-full lg:w-[80%] order-1 h-full  pb-0 lg:pb-[20rem]  flex flex-col lg:pl-7 lg:pr-7 mt-[-2rem]  lg:ml-3 lg:mr-3 lg:mt-0  lg:order-2 bg-center ">
          <section className="text-gray-700 body-font overflow-hidden bg-white">
            <div className="container px-2 py-2 mx-auto">
              <div className="lg:w-5/5 mx-6 flex flex-wrap relative">
                <img
                  alt={data.alt}
                  className="lg:w-full w-full object-contain object-center rounded border border-gray-200 h-80"
                  src={PF + data.reviewPhotos}
                />
                <div className=" w-full lg:pl-1 lg:py-6 mt-6 lg:mt-12 border-t-2  ">
                  <div className="absolute xs:top-[13rem] top-[14rem] left-2 flex space-x-3 items-center justify-center p-2 bg-opacity-5  bg-[#07834b] ">
                    <h2 className=" sm:text-3xl  title-font text-black font-serif sm:font-medium  font-bold tracking-widest b-1">
                      {data.brandName}
                    </h2>
                    <h1 className="text-black sx:text-3xl title-font  font-bold mb-1">
                      {data.mobileName} Review
                    </h1>
                  </div>
                  <div className=" mb-4 absolute  top-[17.3rem] left-2  bg-opacity-5 p-1 w-[99%] bg-[#07834b]">
                    <span className="flex items-center w-full">
                      {/* <svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 text-red-500" viewBox="0 0 24 24">
              <path d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z"></path>
            </svg>
            <svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 text-red-500" viewBox="0 0 24 24">
              <path d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z"></path>
            </svg>
            <svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 text-red-500" viewBox="0 0 24 24">
              <path d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z"></path>
            </svg>
            <svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 text-red-500" viewBox="0 0 24 24">
              <path d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z"></path>
            </svg>
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 text-red-500" viewBox="0 0 24 24">
              <path d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z"></path>
            </svg> */}
                      <span className="text-black ml-3 ">
                        {/* {data.rating} Star */}
                        <div className="flex items-center text-[#F4871E] text-xs">
                          {data.rating === 0
                            ? "Not Rated Yet"
                            : stars.map((_, index) => {
                                return <FaStar key={index} />;
                              })}
                          <div className="flex items-center text-[#F4871E] text-xs pl-2 text-center">
                            {data.rating < 1
                              ? null
                              : Math.round(data.rating * 10) / 10}
                          </div>
                        </div>
                        {/* <h3 className="text-xs ml-3 ">{data.rating}</h3>{" "} */}
                      </span>
                    </span>
                    {/* <span className="flex ml-3 pl-3 py-2 border-l-2 border-gray-200">
            <a className="text-gray-500">
              <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-5 h-5" viewBox="0 0 24 24">
                <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
              </svg>
            </a>
            <a className="ml-2 text-gray-500">
              <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-5 h-5" viewBox="0 0 24 24">
                <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
              </svg>
            </a>
            <a className="ml-2 text-gray-500">
              <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-5 h-5" viewBox="0 0 24 24">
                <path d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"></path>
              </svg>
            </a>
          </span> */}
                  </div>
                  <div className="w-full shadow-md lg:py-6 mt-6 lg:mt-0 shadow-md border-2">
                    <div className="flex flex-col items-center mb-6 ">
                      <h1 className="text-gray-900 text-3xl title-font font-bold mb-2 font-sans ">
                        INTRODUCTION
                      </h1>
                      <div className="h-[4px] w-[150px] bg-green-500 rounded-sm" />
                    </div>
                    {/* <p className="leading-relaxed">{data.introduction}  </p> */}
                    <div
                      className="leading-relaxed pr-4 lg:pl-10 "
                      dangerouslySetInnerHTML={{ __html: data.introduction }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          {data.details != undefined && data.details.length > 0 ? (
            <section className="text-gray-700 body-font overflow-hidden bg-white ">
              <div className="container px-2 py-1 mx-auto">
                <div className="lg:w-5/5 mx-6 flex flex-wrap">
                  <div className=" w-full  lg:py-6 mt-6 lg:mt-0 shadow-md border-2">
                    <div className="flex flex-col items-center mb-6 ">
                      <h1 className="text-gray-900 text-xl title-font font-bold mb-1 font-sans">
                        DETAILS
                      </h1>
                      <div className="h-[4px] w-[150px] bg-green-500 rounded-sm" />
                    </div>
                    {/* <p className="leading-relaxed pr-4"> { data.details} 
       
        </p> */}

                    <div
                      className="leading-relaxed pr-4 lg:pl-10 text-xl font-sans"
                      dangerouslySetInnerHTML={{ __html: data.details }}
                    />
                  </div>

                  {/* <iframe width="50%" className='mt-3 lg:pl-10'  src="https://www.youtube.com/watch?v=hXf8Rg-sdpA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    <ReactPlayer
                      className="mt-3 lg:pl-10 block justify-end"
                      url={data.youtubeUrl}
                      playing={true}
                    />
                  </div>
                </div>
              </div>
            </section>
          ) : (
            ""
          )}
        </div>
      </div>

      <Footer />
    </React.Fragment>
  );
};
function strip(html) {
  var tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  var urlRegex =
    /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  return tmp.innerHTML.replace(urlRegex, function (url) {
    return "\n" + url;
  });
}
function linkify(text) {
  var urlRegex =
    /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  return text.replace(urlRegex, function (url) {
    return '<a href="' + url + '">' + url + "</a>";
  });
}

export default ReviewDetail;
