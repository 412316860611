import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useNavigate } from "react-router";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/auth";

const SignIn = () => {
  const navigate = useNavigate();

  window.scrollTo(0, 0);
  const [viewOtpForm, setViewOtpForm] = useState(false);
  const [user, setUser] = useState(false);

  const firebaseConfig = {
    apiKey: "AIzaSyAvtBvIez5mU80Ojd5Wnnw5mAZFSyxxb9g",
    authDomain: "my-mobiles-guru.firebaseapp.com",
    projectId: "my-mobiles-guru",
    storageBucket: "my-mobiles-guru.appspot.com",
    messagingSenderId: "602783871417",
    appId: "1:602783871417:web:c22348009e97341db58668",
    measurementId: "G-2E2LX4KDZY",
  };

  useEffect(() => {
    let abortController = new AbortController();
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: function (response) {
          this.onSignInSubmit();
        },
        defaultCountry: "IN",
      }
    );
    // alert(localStorage.getItem('phoneNumber'));

    return () => {
      abortController.abort();
    };
  }, []);

  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  } else {
    firebase.app(); // if already initialized, use that one
  }

  const auth = firebase.auth();

  auth.onAuthStateChanged((user) => {
    if (user) {
      setUser(user);
      localStorage.setItem(
        "phoneNumber",
        user.phoneNumber ? user.phoneNumber : ""
      );
    }
  });

  const loginSubmit = (e) => {
    e.preventDefault();

    let phone_number = "+92" + e.target.phone.value;
    const appVerifier = window.recaptchaVerifier;

    auth
      .signInWithPhoneNumber(phone_number, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        setViewOtpForm(true);
        window.confirmationResult = confirmationResult;
        // ...
      })
      .catch((error) => {
        // Error; SMS not sent
        // ...
        alert(error.message);
      });
  };
  const otpSubmit = (e) => {
    e.preventDefault();

    let opt_number = e.target.otp_value.value;

    window.confirmationResult
      .confirm(opt_number)
      .then((confirmationResult) => {
        navigate("/uploadad");
        e.target.otp_value.value = "";
      })
      .catch((error) => {
        // User couldn't sign in (bad verification code?)
        alert(error.message);
      });
  };
  return (
    <div className="wrapper">
      <div id="recaptcha-container"></div>

      {!viewOtpForm ? (
        <div className="form-wrapper">
          <form id="loginForm" onSubmit={loginSubmit}>
            <div className="input-field">
              <label>Phone Number</label>
              <input
                type="text"
                placeholder="3473423008"
                name="phone"
                autoComplete="false"
              />
            </div>
            <button className="main-button" type="submit" id="sign-in-button">
              Sign in
            </button>
          </form>
        </div>
      ) : (
        <div className="form-wrapper" onSubmit={otpSubmit}>
          <form id="otpForm">
            <div className="input-field">
              <label>Enter OTP</label>
              <input
                type="number"
                placeholder="One time password"
                name="otp_value"
                autoComplete="false"
              />
            </div>
            <button className="main-button" type="submit">
              Verify OTP
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default SignIn;
