import React from "react";
import { Helmet } from "react-helmet";

function HelmetComponent({ description, image, title }) {
  return (
    <div>
      <Helmet key={title} defer={false}>
        {/* <!-- HTML Meta Tags --> */}
        <title>{title}</title>
        <meta
          name="description"
          content={description}
          data-react-helmet="true"
        />

        {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content={title} data-react-helmet="true" />
        <meta
          itemprop="description"
          content={description}
          data-react-helmet="true"
        />
        <meta itemprop="image" content={image} data-react-helmet="true" />

        {/* <!-- Facebook Meta Tags --> */}
        <meta
          property="og:url"
          content={`https://www.mymobilesguru.com${window.location.pathname}`}
          data-react-helmet="true"
        />
        <meta property="og:type" content="website" data-react-helmet="true" />
        <meta property="og:title" content={title} data-react-helmet="true" />
        <meta
          property="og:description"
          content={description}
          data-react-helmet="true"
        />
        <meta property="og:image" content={image} data-react-helmet="true" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta
          name="twitter:card"
          content="summary_large_image"
          data-react-helmet="true"
        />
        <meta name="twitter:title" content={title} data-react-helmet="true" />
        <meta
          name="twitter:description"
          content={description}
          data-react-helmet="true"
        />
        <meta name="twitter:image" content={image} data-react-helmet="true" />
      </Helmet>
    </div>
  );
}

export default HelmetComponent;
