import React, { useEffect, useState } from "react";
import {
  MdOutlineArrowBackIosNew,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import { useRef } from "react";
import Sliders from "react-slick";
import axios from "axios";
import { baseURL } from "api/baseURL";
import { useNavigate } from "react-router-dom";
import { FcNext } from "react-icons/fc";

function Slider() {
  const navigate = useNavigate();

  const [slide, setSlide] = useState([]);
  const sliderRef = useRef(null);

  const sliderSettings = {
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 2000,
    infinite: true,
    arrows: false,
  };

  useEffect(() => {
    const getData = async () => {
      const { data } = await axios.get(`${baseURL}/api/v1/slide`);
      setSlide(data.success);
    };
    getData();
  }, []);
  // const PF = `${baseURL}/sliderImages/`;
  const PF = `${baseURL}`;

  return (
    <React.Fragment>
      <div className="w-full h-[30rem] sm:h-[18rem] relative bg-[#f5f5f4] border-b-[10px] border-white">
        <button
          className=" hidden md:block z-10 absolute left-3 top-[50%] -translate-y-[50%]"
          onClick={() => sliderRef.current.slickPrev()}
        >
          <MdOutlineArrowBackIosNew className="bg-green-600 w-10 h-10 rounded-full text-white p-[7px]" />
        </button>
        <Sliders
          ref={sliderRef}
          {...sliderSettings}
          autoplay={true}
          autoplaySpeed={2500}
        >
          {slide.map((items, index) => (
            <div key={index} className="">
              {items.Id !== "" && (
                <div
                  // onClick={() => {
                  //   items.pathname
                  //     ? navigate(`${items.pathname}`)
                  //     : navigate(`/phonespec/${items.Id}`);
                  // }}
                  onClick={() => {
                    navigate(`/phonespec/${items.Id}`);
                  }}
                  className="w-full h-full cursor-pointer "
                >
                  <div className=" flex flex-col-reverse border-1 sm:flex-row items-center justify-center  space-x-4  ">
                    <div className=" flex flex-col justify-center items-center space-y-5 w-[40%]">
                      <h2 className="text-4xl font-extrabold uppercase whitespace-normal font-line ">
                        {items.brandName}
                      </h2>
                      <p className="text-sm font-serif  ">{items.model}</p>

                      {items.Id !== "" && (
                        <button
                          // onClick={() => {
                          //   items.pathname
                          //     ? navigate(`${items.pathname}`)
                          //     : navigate(`/phonespec/${items.Id}`);
                          // }}
                          onClick={() => {
                            navigate(`/phonespec/${items.Id}`);
                          }}
                          className=" h-[35px] border  border-b-2 text-xs rounded-3xl w-[105px] shadow-lg border-green-600 p-1 text-black"
                        >
                          View More
                        </button>
                      )}
                    </div>
                    <div className="  justify-center h-[15rem] flex sm:w-[18rem]   sm:h-[15rem] ">
                      <img
                        className=" pt-3 rounded-md "
                        src={PF + items.photo}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </Sliders>
        <button
          className="hidden md:block z-10 absolute right-3 top-[50%] -translate-y-[50%]"
          onClick={() => sliderRef.current.slickNext()}
        >
          <MdOutlineArrowForwardIos className="bg-green-600 w-10 h-10 rounded-full text-white p-[7px]" />
        </button>
      </div>
    </React.Fragment>
  );
}

export default Slider;
