import React, { useEffect } from "react";
import { FilterMobileCard } from "components";
import { useSelector, useDispatch } from "react-redux";
import { getLatestMobiles } from "../redux/actions/mobileActions";
import { baseURL } from "api/baseURL";
import { Fans, Latest } from "redux/actions/LatestAction";
import ImageAsset from "../components/ImageAsset";

const LeftSideMenuCards = () => {
  const { latestMobile } = useSelector((state) => state.latestPhones);
  const { fans } = useSelector((state) => state.mobileByFans);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(Latest());
    dispatch(Fans());
  }, [dispatch]);

  let leftMenu = [];
  if (latestMobile.length > 0 && fans) {
    leftMenu = [
      {
        title: "Latest Mobiles",
        products: [
          {
            item: latestMobile[0],
            src:
              latestMobile.length < 1
                ? null
                : `${baseURL}${latestMobile[0].mobilePhotos[0]}`,
            name:
              latestMobile.length < 1
                ? null
                : `${latestMobile[0].brandName} ${latestMobile[0].modelNumber}`,
          },
          {
            item: latestMobile[1],
            src:
              latestMobile.length < 1
                ? null
                : `${baseURL}${latestMobile[1].mobilePhotos[0]}`,
            name:
              latestMobile.length < 1
                ? null
                : `${latestMobile[1].brandName} ${latestMobile[1].modelNumber}`,
          },
          {
            item: latestMobile[2],
            src:
              latestMobile.length < 1
                ? null
                : `${baseURL}${latestMobile[2].mobilePhotos[0]}`,
            name:
              latestMobile.length < 1
                ? null
                : `${latestMobile[2].brandName} ${latestMobile[2].modelNumber}`,
          },
          {
            item: latestMobile[3],
            src:
              latestMobile.length < 1
                ? null
                : `${baseURL}${latestMobile[3].mobilePhotos[0]}`,
            name:
              latestMobile.length < 1
                ? null
                : `${latestMobile[3].brandName} ${latestMobile[3].modelNumber}`,
          },
          {
            item: latestMobile[4],
            src:
              latestMobile.length < 1
                ? null
                : `${baseURL}${latestMobile[4].mobilePhotos[0]}`,
            name:
              latestMobile.length < 1
                ? null
                : `${latestMobile[4].brandName} ${latestMobile[4].modelNumber}`,
          },
          {
            item: latestMobile[5],
            src:
              latestMobile.length < 1
                ? null
                : `${baseURL}${latestMobile[5].mobilePhotos[0]}`,
            name:
              latestMobile.length < 1
                ? null
                : `${latestMobile[5].brandName} ${latestMobile[5].modelNumber}`,
          },
        ],
      },
      {
        title: "Top 10 By Fans",
        products: [
          {
            item: fans[0],
            src:
              fans.length < 1 ? null : `${baseURL}${fans[0].mobilePhotos[0]}`,
            name:
              fans.length < 1
                ? null
                : `${fans[0].brandName} ${fans[0].modelNumber}`,
          },
          {
            item: fans[1],
            src:
              fans.length < 1 ? null : `${baseURL}${fans[1].mobilePhotos[0]}`,
            name:
              fans.length < 1
                ? null
                : `${fans[1].brandName} ${fans[1].modelNumber}`,
          },
          {
            item: fans[2],
            src:
              fans.length < 1 ? null : `${baseURL}${fans[2].mobilePhotos[0]}`,
            name:
              fans.length < 1
                ? null
                : `${fans[2].brandName} ${fans[2].modelNumber}`,
          },
          {
            item: fans[3],
            src:
              fans.length < 1 ? null : `${baseURL}${fans[3].mobilePhotos[0]}`,
            name:
              fans.length < 1
                ? null
                : `${fans[3].brandName} ${fans[3].modelNumber}`,
          },
          {
            item: fans[4],
            src:
              fans.length < 1 ? null : `${baseURL}${fans[4].mobilePhotos[0]}`,
            name:
              fans.length < 1
                ? null
                : `${fans[4].brandName} ${fans[4].modelNumber}`,
          },
          {
            item: fans[5],
            src:
              fans.length < 1 ? null : `${baseURL}${fans[5].mobilePhotos[0]}`,
            name:
              fans.length < 1
                ? null
                : `${fans[5].brandName} ${fans[5].modelNumber}`,
          },
        ],
      },

      {
        title: "Top 6 Compaines",
        products: [
          {
            item: 1,
            // src:
            src: `/samsung logo.png`,

            // fans.length < 1
            //   ? null
            //   : `${baseURL}${fans[0].mobilePhotos[0]}`,
            name: "Samsung",
          },
          {
            item: 2,
            src: `/apple logo.png`,
            // fans.length < 1
            //   ? null
            //   : `${baseURL}${fans[1].mobilePhotos[0]}`,
            name: "Apple",
          },
          {
            item: 3,
            src: `/xiaomi logo.jpg`,
            // fans.length < 1
            //   ? null
            //   : `${baseURL}${fans[1].mobilePhotos[0]}`,
            name: "Xiaomi",
          },
          {
            item: 4,
            src: `/oppo logo.jpg`,
            // fans.length < 1
            //   ? null
            //   : `${baseURL}${fans[1].mobilePhotos[0]}`,
            name: "Oppo",
          },
          {
            item: 5,
            src: `/vivo logo.png`,
            // fans.length < 1
            //   ? null
            //   : `${baseURL}${fans[1].mobilePhotos[0]}`,
            name: "vivo",
          },
          {
            item: 6,
            src: `/realme logo.png`,
            // fans.length < 1
            //   ? null
            //   : `${baseURL}${fans[1].mobilePhotos[0]}`,
            name: "Realme",
          },
          {
            item: 7,
            src: `/motorola logo.png`,
            // fans.length < 1
            //   ? null
            //   : `${baseURL}${fans[1].mobilePhotos[0]}`,
            name: "Motorola",
          },
          {
            item: 8,
            src: `/lg logo.png`,
            // fans.length < 1
            //   ? null
            //   : `${baseURL}${fans[1].mobilePhotos[0]}`,
            name: "LG",
          },
          {
            item: 9,
            src: `/huawei logo.jpg`,
            // fans.length < 1
            //   ? null
            //   : `${baseURL}${fans[1].mobilePhotos[0]}`,
            name: "Huawei",
          },
          {
            item: 10,
            src: `/lenovo logo.jpeg`,
            // fans.length < 1
            //   ? null
            //   : `${baseURL}${fans[1].mobilePhotos[0]}`,
            name: "Lenovo",
          },
        ],
      },
    ];
  }
  return (
    <>
      {leftMenu.map(({ title, products }) => {
        return <FilterMobileCard title={title} products={products} />;
      })}

      {/* <!-- mymobilesguruVerticalAd --> */}
      <ins
        className="adsbygoogle"
        style={{
          display: "block",
          // border: "1px solid green",
          height: "300px",
          padding: "5px",
          margin: "10px",
          width: "auto",
        }}
        data-ad-client="ca-pub-4056537892456661"
        data-ad-slot="8801587079"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>

      {/* 2nd AD for mobile guru */}
      <ins
        className="adsbygoogle"
        style={{
          display: "block",
          // border: "1px solid green",
          height: "300px",
          padding: "5px",
          margin: "10px",
          width: "auto",
        }}
        data-ad-client="ca-pub-4056537892456661"
        data-ad-slot="8801587079"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
    </>
  );
};

export default LeftSideMenuCards;
