import React, { useEffect, useState } from "react";
import { Navbar, Footer } from "components";
import { RightSideMenu, LeftSideMenu } from "megaComponents";
import { MobileBrandsNames } from "components";
import QuestionAsk from "components/QuestionAsk";
import SimilarProductSlider from "components/SimilarProductSlider";
import { useRef } from "react";
import Slider from "react-slick";
import axios from "axios";
import { baseURL } from "api/baseURL";
import { useParams } from "react-router-dom";
import { FaStar } from "react-icons/fa";
import { Formik, Form, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import { GadgetSingleAction } from "redux/actions/GadgetAction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
function Gadget() {
  const param = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const id = param.id;
  const PF = `${baseURL}`;
  const [set, setTrue] = useState(false);
  const gadgetSingle = useSelector((store) => store.gadgetById);

  const { loading, singleGadet, error } = gadgetSingle;
  useEffect(() => {
    dispatch(GadgetSingleAction(id));

    /*
    const getData = async () => {
      const { data } = await axios.get(`${baseURL}/api/v1/gadget/${id}`);
      setGadget(data.success);
    }
    getData();*/
  }, [id, set]);
  // useEffect(()=>{
  // setTrue(true);
  // },[items.reviews])
  const sliderRef = useRef(null);

  const sliderSettings = {
    dots: false,
    autoplay: true,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    infinite: true,
    arrows: true,
  };

  const initialValues = {
    name: "",
    email: "",
    oppinion: "",
  };
  const validation = Yup.object({
    name: Yup.string().required("this field is Required"),
    email: Yup.string().required("this field is Required"),
    oppinion: Yup.string().required("this field is Required"),
  });
  return (
    <>
      <Navbar />

      <div className=" lg:flex mx-[3px] flex-grow p-1  w-full">
        <div className="mt-5 lg:w-[20%]  hidden lg:block  ">
          {" "}
          <MobileBrandsNames /> <LeftSideMenu />
        </div>
        <div className=" border-2 bg-[#D2DCE7]  lg:max-w-[51rem] p-1  rounded-md  m-2 flex-grow   ">
          {loading ? (
            // <h1>loading</h1> :

            ""
          ) : (
            <div className="">
              <div className="flex flex-col-reverse flex-grow rounded-xl p-2 items-center justify-around shadow-gray-500 shadow-l lg:max-w-[49rem]  md:h-[17rem] md:flex-row bg-warmGray-100">
                <div className="md:grid  space-y-2 md:ml-0 ml-6 ">
                  <p className="flex  items-center">
                    <span className="font-bold capitalize mb-3 text-lg">
                      {singleGadet.Name}
                    </span>
                  </p>

                  <p className="flex  text-sm items-center">
                    <span className=" font-bold  mr-2">Maker:</span>
                    <span className="text-sm">{singleGadet.maker}</span>
                  </p>
                  <p className="flex  text-sm items-center">
                    <span className="font-bold mr-2">Model:</span>
                    <span className="text-sm">{singleGadet.model}</span>
                  </p>
                  <p className="flex  text-sm items-center  ">
                    <span className=" mr-2 font-bold ">Release Date:</span>
                    <span className="text-sm">{singleGadet.releaseDate}</span>
                  </p>
                  <p className="flex text-sm  items-center mr-3">
                    <span className="font-bold mr-2">Price:</span>
                    <span className="text-sm">{singleGadet.price}</span>
                  </p>
                  <p className="flex text-sm  items-center ">
                    <span className="font-bold mr-2">Colours:</span>
                    <span className="text-sm">{singleGadet.colours}</span>
                  </p>
                </div>
                <div className=" bg-warmGray-100 flex-grow max-w-[13rem] md:w-[17rem]">
                  <Slider ref={sliderRef} {...sliderSettings}>
                    {singleGadet.photo &&
                      singleGadet.photo.map((item) => (
                        <img
                          src={PF + item}
                          alt={singleGadet.alt}
                          className="md:w-[15rem] h-[12rem]"
                        ></img>
                      ))}
                  </Slider>
                  {/* : ''} */}
                </div>
              </div>
              <div className="">
                <button className="w-full ml-auto lg:w-full  mb-1 text-center text-white font-medium  bg-buttonBgColor bg-gradient-to-b text-sm lg:text-xl p-2 pl-10 rounded-[1rem] mt-3 from-buttonBgColorGradian1 to-buttonBgcolorGradian2">
                  Review
                </button>
                <div className=" p-4   bg-warmGray-100 shadow-gray-600 shadow-l rounded-lg">
                  <h1 className="">
                    <p className="font-bold pb-2 text-lg">
                      {singleGadet.reviewTitle}
                    </p>
                    {/* <p className=' text-sm  md:w-[28rem] '>{items.review}
      </p> */}
                  </h1>
                  <div
                    className="leading-relaxed pr-4  text-lg"
                    dangerouslySetInnerHTML={{
                      __html: singleGadet.reviewDetails,
                    }}
                  />
                </div>
              </div>
              <div className="">
                <button className="w-full ml-auto lg:w-full mb-1 text-center text-white font-medium  bg-buttonBgColor bg-gradient-to-b text-sm lg:text-xl p-2 pl-10 rounded-[1rem] mt-3 from-buttonBgColorGradian1 to-buttonBgcolorGradian2">
                  Users Review
                </button>
                <div className="flex-col lg:flex lg:flex-row w-full mt-5   border-black border-b-2 pb-3">
                  <div className="lg:w-[40%] text-[#07844C] text-md font-bold  ">
                    {/* Opinion */}
                  </div>
                  <div className="flex-col justify-center items-center w-full">
                    <div className=" flex flex-col w-full">
                      <div className="flex w-full">
                        <h3 className="w-1/3 text-sm font-bold">Users</h3>
                        <label
                          htmlFor=""
                          className="text-sm font-medium w-[64%]"
                        >
                          Opinions
                        </label>
                        <p>Rating </p>
                        <br />
                      </div>
                      {singleGadet.reviews !== undefined ? (
                        singleGadet.reviews.map((item) => (
                          <div className="flex  w-full border-black border-b-[0.03rem] ">
                            <h3 className="w-1/3 text-sm pt-1 font-bold">
                              {item.name}
                            </h3>
                            <label
                              htmlFor=""
                              className="text-sm pt-1 flex-1 font-medium w-[67%]"
                            >
                              {item.oppinion}
                            </label>
                            <p>
                              {item.stars == 1 ? (
                                <FaStar className="text-[#F4871E] pt-1" />
                              ) : item.stars == 2 ? (
                                <p className="flex pt-1 ">
                                  <FaStar className="text-[#F4871E]" />
                                  <FaStar className="text-[#F4871E]" />
                                </p>
                              ) : item.stars == 3 ? (
                                <p className="flex pt-1">
                                  <FaStar className="text-[#F4871E]" />
                                  <FaStar className="text-[#F4871E]" />
                                  <FaStar className="text-[#F4871E]" />
                                </p>
                              ) : item.stars == 4 ? (
                                <p className="flex pt-1">
                                  <FaStar className="text-[#F4871E]" />
                                  <FaStar className="text-[#F4871E]" />
                                  <FaStar className="text-[#F4871E]" />
                                  <FaStar className="text-[#F4871E]" />
                                </p>
                              ) : item.stars == 5 ? (
                                <p className="flex pt-1">
                                  <FaStar className="text-[#F4871E]" />
                                  <FaStar className="text-[#F4871E]" />
                                  <FaStar className="text-[#F4871E]" />
                                  <FaStar className="text-[#F4871E]" />
                                  <FaStar className="text-[#F4871E]" />
                                </p>
                              ) : (
                                ""
                              )}{" "}
                            </p>

                            <br />
                          </div>
                        ))
                      ) : (
                        <div className="flex w-full border-black border-b-[0.03rem] ">
                          <h3 className="w-1/3 text-sm font-bold">
                            Unavalable
                          </h3>
                          <label
                            htmlFor=""
                            className="text-sm font-medium w-[67%]"
                          >
                            Unavailable
                          </label>
                          <br />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className=" mt-1 p-4  bg-warmGray-100 shadow-gray-600 shadow-l rounded-lg">
                {/* <ReviewForm id={items._id} /> */}
                {/* <ReviewForm id={items._id} /> */}
                <div>
                  {" "}
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validation}
                    onSubmit={async (value, actions) => {
                   
                      try {
                        setTrue(true);
                        const { data } = await axios.put(
                          `${baseURL}/api/v1/gadget/review/${id}`,
                          value
                        );
                        data && alert("Review Added");
                        actions.resetForm();
                        data && setTrue(false);
                      } catch (err) {
                        console.log(err);
                      }
                    }}
                  >
                    {(formik) => (
                      <Form>
                        <div className="flex justify-center">
                          <div className="w-full max-w-md  bg-transparent">
                            <div className="mb-4">
                              <h3 className="block  text-sm font-bold mb-2">
                                Name
                              </h3>
                              <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                name="name"
                                type="text"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                              />
                              <ErrorMessage
                                component="div"
                                className="text-red-700 text-sm"
                                name="name"
                              />
                            </div>
                            <div className="mb-6">
                              <h3 className="block text-sm font-bold mb-2">
                                Email
                              </h3>
                              <input
                                className="shadow appearance-none border  rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                                name="email"
                                type="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                              />
                              <ErrorMessage
                                component="div"
                                className="text-red-700 text-sm"
                                name="email"
                              />
                            </div>
                            <div className="mb-4">
                              <h3
                                className="block text-sm font-bold mb-2"
                                htmlFor="oppinion"
                              >
                                Oppinion
                              </h3>
                              <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                name="oppinion"
                                type="text"
                                value={formik.values.oppinion}
                                onChange={formik.handleChange}
                              />
                              <ErrorMessage
                                component="div"
                                className="text-red-700 text-sm"
                                name="oppinion"
                              />
                            </div>
                            <div className="my-4" id="my-radio-group">
                              <h3 className="block  text-sm font-bold mb-2">
                                Rating
                              </h3>
                              <div>
                                <label className="flex items-center gap-1">
                                  <Field type="radio" name="stars" value="1" />

                                  <FaStar className="text-[#F4871E]" />
                                </label>
                              </div>
                              <div>
                                <label className="flex items-center gap-1">
                                  <Field type="radio" name="stars" value="2" />

                                  <FaStar className="text-[#F4871E]" />
                                  <FaStar className="text-[#F4871E]" />
                                </label>
                              </div>
                              <div>
                                <label className="flex items-center gap-1">
                                  <Field type="radio" name="stars" value="3" />

                                  <FaStar className="text-[#F4871E]" />
                                  <FaStar className="text-[#F4871E]" />
                                  <FaStar className="text-[#F4871E]" />
                                </label>
                              </div>
                              <div>
                                <label className="flex items-center gap-1">
                                  <Field type="radio" name="stars" value="4" />

                                  <FaStar className="text-[#F4871E]" />
                                  <FaStar className="text-[#F4871E]" />
                                  <FaStar className="text-[#F4871E]" />
                                  <FaStar className="text-[#F4871E]" />
                                </label>
                              </div>
                              <div>
                                <label className="flex items-center gap-1">
                                  <Field type="radio" name="stars" value="5" />

                                  <FaStar className="text-[#F4871E]" />
                                  <FaStar className="text-[#F4871E]" />
                                  <FaStar className="text-[#F4871E]" />
                                  <FaStar className="text-[#F4871E]" />
                                  <FaStar className="text-[#F4871E]" />
                                </label>
                              </div>
                            </div>
                            <ErrorMessage
                              component="div"
                              className="text-red-700 text-sm"
                              name="stars"
                            />
                            <div className="flex items-center justify-between">
                              <button
                                className="bg-green-700 hover:bg-green-800 text-white font-bold py-2 px-8  rounded focus:outline-none focus:shadow-outline"
                                type="submit"
                              >
                                Add Review
                              </button>
                              <button
                                className="btn text-sm font-light rounded-sm p-2 pl-6 pr-6 bg-red-200 btn-dark mt-3 hidden"
                                type="reset"
                              >
                                Reset
                              </button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
              <div className="flex-grow">
                <div className="text-center flex-grow">
                  {" "}
                  <button className="w-full ml-auto lg:w-full mb-1 text-center text-white font-medium  bg-buttonBgColor bg-gradient-to-b text-sm lg:text-xl p-2 pl-10 rounded-[1rem] mt-3 from-buttonBgColorGradian1 to-buttonBgcolorGradian2">
                    Similar Product
                  </button>
                </div>
                <div className=" p-4   bg-[#D2DCE7] shadow-gray-600 shadow-l rounded-lg">
                  <div className="flex"></div>

                  <div className="p-1 w-full md:flex  justify-center ">
                    <SimilarProductSlider id={singleGadet._id} />
                  </div>
                </div>
              </div>
              <div className="">
                <button className="w-full ml-auto lg:w-full mb-1 text-center text-white font-medium  bg-buttonBgColor bg-gradient-to-b text-sm lg:text-xl p-2 pl-10 rounded-[1rem] mt-3 from-buttonBgColorGradian1 to-buttonBgcolorGradian2">
                  FAQs
                </button>
                <QuestionAsk />
              </div>
            </div>
          )}
        </div>

        <div className="lg:w-[247px]   text-center order-2  lg:absolute  lg:order-3  top-3 right-1">
          <div className="flex flex-col">
            <button
              className="w-auto cursor-pointer border-2 bg-transparent p-1 mt-5 lg:mt-0  rounded-[1rem]"
              onClick={() => navigate("/advancesearch")}
            >
              Advance Search
            </button>
            <button
              className="w-auto cu border-2 bg-transparent mt-8 p-1  rounded-[1rem]"
              onClick={() => navigate("/reviews")}
            >
              Videos Reviews
            </button>
            <button
              className="w-auto text-white  bg-[#F4871E] font-semibold text-md mt-3 p-1 pl-[2rem] pr-[2rem] rounded-[1rem]"
              onClick={() => navigate("/uploadad")}
            >
              Buy And Sell <br /> Smart Phone
            </button>
          </div>
        </div>
        <div className="lg:min-w-[247px] lg:mt-[52px]  text-center order-2   lg:order-3  top-3 right-0">
          {" "}
          <RightSideMenu />
        </div>

        <div className=" lg:hidden   ">
          {" "}
          <MobileBrandsNames /> <LeftSideMenu />{" "}
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Gadget;
