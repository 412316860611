import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/auth";

// react router
import { Routes, Route, Link } from "react-router-dom";
import SignIn from "components/SignIn";
import Home from "components/Home";

//components
// import SignIn from "./components/SignIn";
// import Home from "./components/Home";

const Auth = () => {
  const [viewOtpForm, setViewOtpForm] = useState(false);
  const [user, setUser] = useState(false);
  const navigate = useNavigate();
  // const firebaseConfig = {
  //   apiKey: "AIzaSyBtugZGIDzP4PDteKARHRLoxmJPMw7r_OY",
  //   authDomain: "fir-auth-ffd18.firebaseapp.com",
  //   projectId: "fir-auth-ffd18",
  //   storageBucket: "fir-auth-ffd18.appspot.com",
  //   messagingSenderId: "1073191415726",
  //   appId: "1:1073191415726:web:e60401894258d7603ca23f",
  //   measurementId: "G-GZ2V82BHK4"
  // };

  const firebaseConfig = {
    apiKey: "AIzaSyAvtBvIez5mU80Ojd5Wnnw5mAZFSyxxb9g",
    authDomain: "my-mobiles-guru.firebaseapp.com",
    projectId: "my-mobiles-guru",
    storageBucket: "my-mobiles-guru.appspot.com",
    messagingSenderId: "602783871417",
    appId: "1:602783871417:web:c22348009e97341db58668",
    measurementId: "G-2E2LX4KDZY",
  };

  useEffect(() => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: function (response) {
          this.onSignInSubmit();
        },
        defaultCountry: "IN",
      }
    );
  }, []);

  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  } else {
    firebase.app(); // if already initialized, use that one
  }

  const auth = firebase.auth();

  auth.onAuthStateChanged((user) => {
    if (user) {
      setUser(user);
    }
  });

  const loginSubmit = (e) => {
    e.preventDefault();

    let phone_number = "+92" + e.target.phone.value;
    const appVerifier = window.recaptchaVerifier;

    auth
      .signInWithPhoneNumber(phone_number, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        setViewOtpForm(true);
        window.confirmationResult = confirmationResult;
        // ...
      })
      .catch((error) => {
        // Error; SMS not sent
        // ...
        alert(error.message);
      });
  };

  const otpSubmit = (e) => {
    e.preventDefault();

    let opt_number = e.target.otp_value.value;

    window.confirmationResult
      .confirm(opt_number)
      .then((confirmationResult) => {
        window.open("/", "_self");
      })
      .catch((error) => {
        // User couldn't sign in (bad verification code?)
        alert(error.message);
      });
  };

  const signOut = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        window.open("/signin", "_self");
      })
      .catch((error) => {
        // An error happened.
        console.log(error);
      });
  };

  return (
    <>
      <div id="recaptcha-container"></div>
      <Routes>
        <Route
          path="/signout"
          element={<Home signOut={signOut} user={user} />}
        />
        <Route
          path="signin"
          element={
            <SignIn
              loginSubmit={loginSubmit}
              otpSubmit={otpSubmit}
              viewOtpForm={viewOtpForm}
            />
          }
        />
      </Routes>
    </>
  );
};

export default Auth;
