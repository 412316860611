import React, { useEffect, useState } from "react";
import {
  MdOutlineArrowBackIosNew,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import { useRef } from "react";
import Slider from "react-slick";
import axios from "axios";
import { baseURL } from "api/baseURL";

function SimilarProductSlider(props) {
  const sliderRef = useRef(null);

  const sliderSettings = {
    dots: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    speed: 500,
    infinite: true,
    arrows: true,
  };

  const PF = `${baseURL}`;

  const { id } = props;
  const [simlilar, setSimilar] = useState([]);
  useEffect(() => {
    const related = async () => {
      const { data } = await axios.get(
        `${baseURL}/api/v1/gadget/related/${id}`
      );
      setSimilar(data.success);
    };
    related();
  }, [id]);

  return (
    <div className="md:w-[31rem]   ">
      {simlilar.length !== 1 ? (
        <Slider {...sliderSettings}>
          {
            // similarProductData.map((items, index) => (
            simlilar.map((items) => (
              <div className="flex   justify-center   ">
                <div className="m-[8px]  bg-warmGray-100   rounded-lg border-4  shadow-sm shadow-black pt-2 px-2">
                  <img
                    src={PF + items.photo[0]}
                    className=" rounded-lg h-[10rem] md:h-[12rem] w-full "
                  />
                  <h1 className="text-xs md:text-sm overflow-hidden">
                    Name: <span className="ml-2"> {items.Name} </span>{" "}
                  </h1>

                  <p className="text-xs font-bold  md:text-sm">
                    Maker:{" "}
                    <span className="font-semibold ml-2"> {items.maker} </span>
                  </p>

                  <p className="text-xs font-bold md:text-sm">
                    {" "}
                    Price:{" "}
                    <span className="font-semibold ml-4"> {items.price} </span>
                  </p>
                  <p className="text-xs font-bold md:text-sm">
                    {" "}
                    Mode:{" "}
                    <span className="font-semibold ml-3"> {items.model} </span>
                  </p>
                  <p className="text-xs font-bold md:text-sm">
                    {" "}
                    Colours:{" "}
                    <span className="font-semibold ml-2">
                      {" "}
                      {items.colours}{" "}
                    </span>
                  </p>
                </div>
              </div>
            ))
          }
        </Slider>
      ) : (
        <div className="grid justify-center items-center w-full">
          {simlilar.map((items) => (
            <div className="bg-white w-52 rounded-md  cursor-pointer">
              <div className="flex justify-center">
                <img
                  className=" object-top px-1 py-1 xs:p-2   sm:h-[14rem]"
                  src={PF + items.photo[0]}
                  alt=""
                />
              </div>

              <div className=" pt-1 w-full pl-6 capitalize">
                <h3 className="font-bold ">{items.Name}</h3>
                <div className="flex">
                  <p className=" md:text-base text-sm   mr-1 font-bold ">
                    Price:
                  </p>
                  <p className="text-sm ">{items.price}</p>
                </div>
                <div className="flex">
                  <p className=" md:text-base text-sm   mr-1 font-bold ">
                    Model:
                  </p>
                  <p className="text-sm ">{items.model}</p>
                </div>
                <div className="flex">
                  <p className="md:text-base text-sm mr-1 font-bold ">
                    colour:
                  </p>
                  <p className="text-sm ">{items.colours}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default SimilarProductSlider;
