import React, { useEffect } from "react";
import { Footer, ImageAsset, FilterMobileCard, Navbar } from "components";
import { useNavigate, useLocation } from "react-router";
import Slider from "react-slick";
import { useSelector, useDispatch } from "react-redux";
import { getMobileById } from "../redux/actions/mobileActions";
import { baseURL } from "api/baseURL";
import { getLatestMobiles } from "../redux/actions/mobileActions";
import { useParams } from "react-router-dom";
import { BsCamera, BsFullscreen, BsBatteryCharging } from "react-icons/bs";
import { MdOutlineMemory } from "react-icons/md";
import HelmetComponent from "components/HelmetComponent";

function currencyFormat(num) {
  return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

const SmartPhoneDetailDesc = () => {
  // window.scrollTo(0, 0);

  const { singleMobile, latestMobile } = useSelector(
    (state) => state.mobileReducer
  );
  const location = useLocation();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getLatestMobiles());
  }, []);
  const handleFetchMobileById = (value) => dispatch(getMobileById(value));

  const check = Object.keys(singleMobile).length !== 0;

  const total = check
    ? singleMobile.reviews.reduce(
        //reduce go through the array and cartItem is the each item in the array
        (accumulatedTotal, review) => accumulatedTotal + parseInt(review.stars),
        0 //0 is the start point of accumulatedTotal
      )
    : 1;

  const SlickArrowLeft = ({ currentSlide, slideCount, imgsrc, ...props }) => (
    <div className=" bg-white rounded-full w-fit p-2 ">
      <ImageAsset
        alt="prevArrow"
        {...props}
        className="  h-[1rem]"
        src={imgsrc}
      />
    </div>
  );

  const leftMenu = [
    {
      title: "Latest Mobiles",
      products: [
        {
          item: latestMobile[0],
          src:
            latestMobile.length < 1
              ? null
              : `${baseURL}${latestMobile[0].mobilePhotos[0]}`,
          name:
            latestMobile.length < 1
              ? null
              : `${latestMobile[0].brandName} ${latestMobile[0].modelNumber}`,
        },
        {
          item: latestMobile[1],
          src:
            latestMobile.length < 1
              ? null
              : `${baseURL}${latestMobile[1].mobilePhotos[0]}`,
          name:
            latestMobile.length < 1
              ? null
              : `${latestMobile[1].brandName} ${latestMobile[1].modelNumber}`,
        },
        {
          item: latestMobile[2],
          src:
            latestMobile.length < 1
              ? null
              : `${baseURL}${latestMobile[2].mobilePhotos[0]}`,
          name:
            latestMobile.length < 1
              ? null
              : `${latestMobile[2].brandName} ${latestMobile[2].modelNumber}`,
        },
        {
          item: latestMobile[3],
          src:
            latestMobile.length < 1
              ? null
              : `${baseURL}${latestMobile[3].mobilePhotos[0]}`,
          name:
            latestMobile.length < 1
              ? null
              : `${latestMobile[3].brandName} ${latestMobile[3].modelNumber}`,
        },
        {
          item: latestMobile[4],
          src:
            latestMobile.length < 1
              ? null
              : `${baseURL}${latestMobile[4].mobilePhotos[0]}`,
          name:
            latestMobile.length < 1
              ? null
              : `${latestMobile[4].brandName} ${latestMobile[4].modelNumber}`,
        },
        {
          item: latestMobile[5],
          src:
            latestMobile.length < 1
              ? null
              : `${baseURL}${latestMobile[5].mobilePhotos[0]}`,
          name:
            latestMobile.length < 1
              ? null
              : `${latestMobile[5].brandName} ${latestMobile[5].modelNumber}`,
        },
      ],
    },
    {
      title: "Top 6 By Fans",
      products: [
        {
          item: latestMobile[0],
          src:
            latestMobile.length < 1
              ? null
              : `${baseURL}${latestMobile[0].mobilePhotos[0]}`,
          name:
            latestMobile.length < 1
              ? null
              : `${latestMobile[0].brandName} ${latestMobile[0].modelNumber}`,
        },
        {
          item: latestMobile[1],
          src:
            latestMobile.length < 1
              ? null
              : `${baseURL}${latestMobile[1].mobilePhotos[0]}`,
          name:
            latestMobile.length < 1
              ? null
              : `${latestMobile[1].brandName} ${latestMobile[1].modelNumber}`,
        },
        {
          item: latestMobile[2],
          src:
            latestMobile.length < 1
              ? null
              : `${baseURL}${latestMobile[2].mobilePhotos[0]}`,
          name:
            latestMobile.length < 1
              ? null
              : `${latestMobile[2].brandName} ${latestMobile[2].modelNumber}`,
        },
        {
          item: latestMobile[3],
          src:
            latestMobile.length < 1
              ? null
              : `${baseURL}${latestMobile[3].mobilePhotos[0]}`,
          name:
            latestMobile.length < 1
              ? null
              : `${latestMobile[3].brandName} ${latestMobile[3].modelNumber}`,
        },
        {
          item: latestMobile[4],
          src:
            latestMobile.length < 1
              ? null
              : `${baseURL}${latestMobile[4].mobilePhotos[0]}`,
          name:
            latestMobile.length < 1
              ? null
              : `${latestMobile[4].brandName} ${latestMobile[4].modelNumber}`,
        },
        {
          item: latestMobile[5],
          src:
            latestMobile.length < 1
              ? null
              : `${baseURL}${latestMobile[5].mobilePhotos[0]}`,
          name:
            latestMobile.length < 1
              ? null
              : `${latestMobile[5].brandName} ${latestMobile[5].modelNumber}`,
        },
      ],
    },

    {
      title: "Top 6 Compaines",
      products: [
        {
          item: 1,
          // src:
          src: `/samsung logo.png`,

          // fans.length < 1
          //   ? null
          //   : `${baseURL}${fans[0].mobilePhotos[0]}`,
          name: "Samsung",
        },
        {
          item: 2,
          src: `/apple logo.png`,
          // fans.length < 1
          //   ? null
          //   : `${baseURL}${fans[1].mobilePhotos[0]}`,
          name: "Apple",
        },
        {
          item: 3,
          src: `/xiaomi logo.jpg`,
          // fans.length < 1
          //   ? null
          //   : `${baseURL}${fans[1].mobilePhotos[0]}`,
          name: "Xiaomi",
        },
        {
          item: 4,
          src: `/oppo logo.jpg`,
          // fans.length < 1
          //   ? null
          //   : `${baseURL}${fans[1].mobilePhotos[0]}`,
          name: "Oppo",
        },
        {
          item: 5,
          src: `/vivo logo.png`,
          // fans.length < 1
          //   ? null
          //   : `${baseURL}${fans[1].mobilePhotos[0]}`,
          name: "vivo",
        },
        {
          item: 6,
          src: `/realme logo.png`,
          // fans.length < 1
          //   ? null
          //   : `${baseURL}${fans[1].mobilePhotos[0]}`,
          name: "Realme",
        },
        {
          item: 7,
          src: `/motorola logo.png`,
          // fans.length < 1
          //   ? null
          //   : `${baseURL}${fans[1].mobilePhotos[0]}`,
          name: "Motorola",
        },
        {
          item: 8,
          src: `/lg logo.png`,
          // fans.length < 1
          //   ? null
          //   : `${baseURL}${fans[1].mobilePhotos[0]}`,
          name: "LG",
        },
        {
          item: 9,
          src: `/huawei logo.jpg`,
          // fans.length < 1
          //   ? null
          //   : `${baseURL}${fans[1].mobilePhotos[0]}`,
          name: "Huawei",
        },
        {
          item: 10,
          src: `/lenovo logo.jpeg`,
          // fans.length < 1
          //   ? null
          //   : `${baseURL}${fans[1].mobilePhotos[0]}`,
          name: "Lenovo",
        },
      ],
    },
  ];
  const fev = useParams();

  const locationz = useLocation();
  const idz = locationz.pathname.split("/")[2];

  useEffect(() => {
    handleFetchMobileById(fev);

    if (Object.keys(singleMobile).length === 0) {
      // const id = localStorage.getItem("detailId");
      // const id = localStorage.getItem("detailId");
      // const fev = useLocation.pathname.split('/')[2];
      // const fev = useParams();
      // handleFetchMobileById(id);
      // handleFetchMobileById(id);
      var id = location.pathname.split("/")[2];
      // handleFetchMobileById(fev);
      //  alert("coming in if")

      // const id = localStorage.getItem("detailId") ;
      // const id = localStorage.getItem("detailId") ;
      // handleFetchMobileById(ids);
      handleFetchMobileById(id);
    } else {
      return;
    }
  }, [singleMobile, fev.id]);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  let navPathReview = singleMobile.reviewUrl
    ? singleMobile.reviewUrl
    : "https://www.youtube.com/channel/UCk-qE2_UQ2tPJnsCTwKmi9g";
  let strPhoneSpecPath = `/${singleMobile.brandName}-${singleMobile.modelNumber}`;
  strPhoneSpecPath = `${strPhoneSpecPath.replace(/\s/g, "-")}/${
    singleMobile._id
  }`;

  return (
    <React.Fragment>
      <Navbar />
      {/* <HelmetComponent
        title={singleMobile.brandName +" "+ singleMobile.modelNumber+ " mymobilesguru.com" || "not updated"}
        description={singleMobile.brandName+ " mymobilesguru.com" || "not updated"}
        image="https://picsum.photos/300"
      /> */}
      <div className="mainContent  mt-5 lg:mt-20  flex flex-col lg:flex-row lg:px-10">
        <div className="w-full lg:w-[20%]  order-2 lg:order-1 px-4 lg:px-0">
          {leftMenu.map(({ title, products }) => {
            return <FilterMobileCard title={title} products={products} />;
          })}
        </div>

        <div className="w-full lg:w-[80%] h-full order-1 lg:order-2  pb-[2rem] bg-[#fff]  flex flex-col pl-2 pr-2 lg:mt-5 lg:m-5 rounded-[0.2rem] ">
          {/* First Coloum */}

          <div className="bg-warmGray-100 border-2 border-black rounded-[0.2rem] p-[1rem] lg:pb-[1rem] flex-col mt-5 w-full">
            <div className="flex flex-col  lg:flex-row">
              <div className=" w-full lg:w-1/4 h-[25rem] lg:h-[22rem] ">
                {/* carousel start */}
                <div className="sm:pt-6">
                  <Slider {...settings}>
                    {Object.keys(singleMobile).length !== 0
                      ? singleMobile.mobilePhotos.map((imageName) => {
                          return (
                            <div>
                              <img
                                className=" object-top h-[15rem]"
                                src={`${baseURL}${imageName}`}
                                alt={`${singleMobile?.alt}`}
                              />
                            </div>
                          );
                        })
                      : null}
                  </Slider>
                </div>
                {/* carousel ends  */}
              </div>
              <div className="  flex lg:mt-[1rem] w-full sm:h-[15.1rem] h-[26rem] space-y-2">
                <div className="w-full lg:w-full flex-col text-sm  justify-center h-full   md:space-y-5 space-y-2  ">
                  <div className="flex  space-y-5">
                    <h1 className=" font-semibold  flex flex-col space-y-5">
                      <div className="flex ">
                        <ImageAsset
                          className="w-[1.6rem] h-[1.6rem]  "
                          src="phoneIcon"
                        />
                        <h1 className="ml-3">
                          {" "}
                          <strong>Mobile </strong>
                          {singleMobile ? singleMobile.brandName : ""}{" "}
                          {(" ", singleMobile ? singleMobile.modelNumber : "")}
                        </h1>
                      </div>
                      <div className="flex">
                        <ImageAsset
                          className="w-[1.6rem] h-[1.6rem] mr-2 "
                          src="phoneIcon"
                        />
                        <strong className="mr-2 ">Price </strong>
                        {" Rs. "}
                        {Object.keys(singleMobile).length !== 0
                          ? currencyFormat(singleMobile.priceInPKR)
                          : ""}{" "}
                      </div>
                      <div className="flex">
                        <ImageAsset
                          className="w-[1.6rem] h-[1.6rem] mr-2 "
                          src="phoneIcon"
                        />
                        <strong className="mr-2">USD </strong>
                        {" $ "}
                        {/* {singleMobile ? singleMobile.priceInUSD : ""}
                    {"$"} */}
                        {Object.keys(singleMobile).length !== 0
                          ? currencyFormat(singleMobile.priceInUSD)
                          : ""}{" "}
                      </div>
                    </h1>
                  </div>

                  <div className="flex ">
                    <ImageAsset
                      className="w-[1.6rem] h-[1.6rem] "
                      src="ChatIcon"
                    />
                    <h1 className="ml-3">
                      <strong>Release Date </strong>{" "}
                      {singleMobile ? singleMobile.releaseDate : ""}
                    </h1>
                  </div>

                  <div className=" sm:h-[4.2rem] w-full flex justify-between px-2 sm:flex-row flex-col">
                    <div className="flex flex-col ">
                      <h3 className=" xs:text-4xl lg:text-3xl  text-xl font-bold md:mr-1  mb-2 flex justify-center  ">
                        {" "}
                        <BsFullscreen />
                      </h3>
                      <label
                        htmlFor=""
                        className="text-sm font-medium text-center"
                      >
                        {check ? `${singleMobile.display.size} inch` : ""}
                      </label>
                      <label
                        htmlFor=""
                        className=" sm:text-sm text-xs font-medium text-center"
                      >
                        {check ? `${singleMobile.display.resolution} ` : ""}
                      </label>
                    </div>

                    <div>
                      <h3 className=" text-4xl font-bold  flex justify-center sm:pb-2 ">
                        {" "}
                        <BsCamera />
                      </h3>

                      <label
                        htmlFor=""
                        className="text-sm font-medium text-center flex justify-center"
                      >
                        {check ? `${singleMobile.camera.main.value} MP ` : ""}
                      </label>
                    </div>
                    {/* <div className="sm:h-[109%] w-full flex justify-end content-end "> */}

                    <div className="flex flex-col justify-center   sm:w-[10rem]">
                      <h3 className=" text-4xl font-bold  flex justify-center pb-2 text-zinc-500">
                        {" "}
                        <MdOutlineMemory />
                      </h3>

                      <label
                        htmlFor=""
                        className="text-sm font-medium text-center"
                      >
                        {check
                          ? `${singleMobile.memory.ram.value}  ${singleMobile.memory.ram.unit}/ ${singleMobile.memory.rom.value}  ${singleMobile.memory.rom.unit}`
                          : ""}
                      </label>
                    </div>

                    <div className="flex flex-col justify-center   sm:w-[11rem] xs:w-[10rem]  ">
                      <h3 className="  text-4xl font-bold  flex justify-center pb-2 ">
                        {" "}
                        <BsBatteryCharging />
                      </h3>

                      {/* <h3 className=" text-sm font-bold  text-center "> <BsBatteryCharging/></h3> */}
                      <label
                        htmlFor=""
                        className="text-sm font-medium text-center"
                      >
                        {check ? `${singleMobile.battery.capacity} mAh` : ""}
                      </label>
                      <label
                        htmlFor=""
                        className="text-sm font-medium text-center overflow-y-hidden"
                      >
                        {check ? `${singleMobile.battery.description} ` : ""}
                      </label>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
                {/* </div> */}

                {
                  // <div className="sm:h-[109%] w-full flex justify-end content-end ">
                  //   <div className="flex flex-col justify-end   sm:w-[10rem]" >
                  //   <h3 className=" text-4xl font-bold  flex justify-center pb-2 text-zinc-500"> <MdOutlineMemory/></h3>
                  //   <label htmlFor="" className="text-sm font-medium text-center">
                  //     {check
                  //       ? `${singleMobile.memory.ram.value}  ${singleMobile.memory.ram.unit}/ ${singleMobile.memory.rom.value}  ${singleMobile.memory.rom.unit}`
                  //       : ""}
                  //   </label>
                  //   </div>
                  //   <div className='flex flex-col justify-end  sm:w-[11rem] xs:w-[10rem]  '>
                  //   <h3 className="  text-4xl font-bold  flex justify-center pb-2 "> <BsBatteryCharging/></h3>
                  //   {/* <h3 className=" text-sm font-bold  text-center "> <BsBatteryCharging/></h3> */}
                  //   <label htmlFor="" className="text-sm font-medium text-center">
                  //     {check ? `${singleMobile.battery.capacity} mAh` : ""}
                  //   </label>
                  //   <label htmlFor="" className="text-sm font-medium text-center overflow-y-hidden">
                  //     {check ? `${singleMobile.battery.description} ` : ""}
                  //   </label>
                  //   </div>
                  //    </div>
                }
              </div>
              {/* <div className="flex mt-0 lg:mt-[3rem] h-[10rem] items-center justify-center lg:w-1/3 ">
                <div>
                  <div className="flex justify-center mb-2">
                    <h1>66%</h1>
                  </div>
                  <div>
                    <h1>16,977,86 Hit</h1>
                  </div>
                </div>
                <div className="ml-4">
                  <div>
                    <div className="flex justify-center mb-2">
                      <ImageAsset
                        className="w-[1.6rem] h-[1.6rem]  "
                        src="starIcon"
                      />
                    </div>
                    <div>
                      <h1>Become Like 17234</h1>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            <div>
              <div className="flex w-full  justify-center   lg:mt-[-4rem]  lg:gap-x-16 text-xs">
                {/* <div>
                  <div className="flex justify-center ">
                    <ImageAsset
                      className="w-[2.6rem] h-[2.6rem]   "
                      src="phoneIcon"
                    />
                  </div>
                  <div>
                    <h1>
                    {Object.keys(singleMobile).length !== 0
                     ?singleMobile.priceInPKR : "" } 
                      
                    {" "} Pkr </h1>
                  </div>
                </div> */}
                {/* <div>
                  <div className="flex justify-center">
                    <ImageAsset
                      className="w-[2.6rem] h-[2.6rem]   "
                      src="phoneIcon"
                    />
                  </div>
                  <div>
                    <h1>
                  {Object.keys(singleMobile).length !== 0
                     ?singleMobile.priceInUSD : "" } 
                     {" "} $ </h1>

                  
                  </div>
                </div> */}
                {/* <div>
                  <div className="flex justify-center">
                    <ImageAsset
                      className="w-[2.6rem] h-[2.6rem]   "
                      src="phoneIcon"
                    />
                  </div>
                 
                  <div>
                    <h1>
                   {Object.keys(singleMobile).length !== 0
                     ?singleMobile.releaseDate : "" 
                  }
                     </h1>
                      
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          {/* second col */}
          <div className="bg-warmGray-100 w-full flex rounded-[0.5rem] mt-5 border-2 border-black">
            <div
              className={` ${
                location.pathname === `${strPhoneSpecPath}/phonedescription`
                  ? "bg-[#07844C] text-white "
                  : "bg-white"
              } w-1/2 h-full text-center py-8 lg:py-4 cursor-pointer font-bold text-xl`}
            >
              {/* <h1 onClick={() => navigate("/phonedescription")}> */}
              <h1> Description</h1>
            </div>

            <div className="w-1/2 text-center py-8 lg:py-4 cursor-pointer font-bold text-xl">
              <h1
                onClick={() => {
                  // navigate(``);
                  let str = `/${singleMobile.brandName}-${singleMobile.modelNumber}`;
                  navigate(`${str.replace(/\s/g, "-")}/${singleMobile._id}`, {
                    state: singleMobile,
                  });
                }}
              >
                Specification
              </h1>
            </div>

            {(() => {
              return (
                <div
                  className={`w-1/2 text-center cursor-pointer font-bold text-xl py-4`}
                >
                  <a target="_blank" href={navPathReview}>
                    Video Review
                  </a>
                </div>
              );
            })()}

            {/* <div
              className={`w-1/2 text-center cursor-pointer font-bold text-xl py-4`}
            >
              
               <a target="_blank" href = "https://www.youtube.com/channel/UCd94jwgvLDI_FB_iFvKPZcQ" >Video Review</a>
              
            </div> */}
          </div>

          {/* Third Col */}
          <div className="bg-warmGray-100 w-full flex-col p-2 rounded-[0.5rem] mt-5 pb-[5rem] border-2 border-black">
            <div className="w-full text-xs mt-5">
              <p>
                {" "}
                <h1>
                  <div
                    className="flex items-center justify-start flex-wrap"
                    dangerouslySetInnerHTML={{
                      __html: singleMobile.shortDescription,
                    }}
                  />
                </h1>
              </p>

              {/* <p className=" text-2xl"> {hello}</p> */}
            </div>
            <hr className="my-4 text-black" />
            <div className="w-full text-xs mt-5">
              {/* <p className="flex items-center justify-start flex-wrap">
                {singleMobile.longDescription}
              </p> */}
              <div
                className="flex items-center justify-start flex-wrap"
                dangerouslySetInnerHTML={{
                  __html: singleMobile.longDescription,
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-[10rem]">
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default SmartPhoneDetailDesc;
