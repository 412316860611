import React from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { getSingleMobile } from "redux/actions/mobileActions";
import { isFilterMobile } from "../redux/actions/mobileActions";
import { globalSetNext } from "screens/HomeScreen";

const FilterMobileCard = (props) => {
  const { title, products } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSetSingleMobile = (value) => dispatch(getSingleMobile(value));
  const handleIsFilter = (value) => dispatch(isFilterMobile(value));

  // let strPhoneSpecPath = `/${item.brandName}-${item.modelNumber}`;
  // strPhoneSpecPath = `${strPhoneSpecPath.replace(/\s/g, '-')}`;
  return (
    <div className="w-full ">
      <div className="card rounded-tl-[3rem]  mt-5 pb-4  rounded-b-[3rem]  bg-backgroundGreenColor w-full sm:w-[50%] md:w-[60%] lg:w-full mx-auto">
        <div className="cardRow w-full text-lg text-center pt-5 pb-2 text-white">
          <h1>{title}</h1>
        </div>
        <div className="w-full border-b border-white mr-2"></div>
        <div>
          <div className=" flex flex-parent gap-x-[1rem] justify-center pl-2 pr-1 mt-5">
            {products.slice(0, 3).map(({ name, src, item },index) => {
              return (
                <div key={index}
                  className="flex-item cursor-pointer"
                  onClick={() => {
                    if (title === "Top 6 Compaines") {
                      navigate("/mobilelist", {
                        state: { title: "company", name, isFilter: true },
                      });
                      globalSetNext(true);
                      handleIsFilter(true);
                    } else {
                      localStorage.setItem("detailId", item._id);
                      let strPhoneSpecPath = `/${item.brandName}-${item.modelNumber}`;
                      strPhoneSpecPath = `${strPhoneSpecPath.replace(
                        /\s/g,
                        "-"
                      )}`;
                      navigate(`${strPhoneSpecPath}/${item._id}`, {
                        state: item,
                      });
                      handleSetSingleMobile(item);
                    }
                  }}
                >
                  <div className="flex flex-col w-full text-xs items-center text-white justify-around">
                    <img
                      className="w-[60px] h-[5rem] md:h-[4rem] justify-around  bg-transparent  object-contain"
                      src={src}
                      alt="omg"
                    />
                    <h3 className="w-[60px] justify-items-center">{name} </h3>
                  </div>
                </div>
              );
            })}
          </div>
          <div className=" flex flex-parent gap-x-[1rem] justify-center pl-2 pr-1 mt-5">
            {products.slice(3, 6).map(({ name, src, item },index) => {
              return (
                <div key={index} className="flex-item ">
                  <div
                    className="flex flex-col w-full text-xs items-center text-white justify-between  cursor-pointer"
                    // onClick={() => {
                    //   localStorage.setItem("detailId", item._id);
                    //   navigate(`/phonespec/${item._id}`, {
                    //     state: item,
                    //   });
                    //   handleSetSingleMobile(item);
                    // }}
                    onClick={() => {
                      if (title == "Top 6 Compaines") {
                        navigate("/mobilelist", {
                          state: { title: "company", name, isFilter: true },
                        });
                        handleIsFilter(true);
                      } else {
                        localStorage.setItem("detailId", item._id);
                        let strPhoneSpecPath = `/${item.brandName}-${item.modelNumber}`;
                        strPhoneSpecPath = `${strPhoneSpecPath.replace(
                          /\s/g,
                          "-"
                        )}`;
                        navigate(`${strPhoneSpecPath}/${item._id}`, {
                          state: item,
                        });
                        handleSetSingleMobile(item);
                      }
                    }}
                  >
                    <img
                      className="w-[60px] h-[5rem] md:h-[4rem] justify-around "
                      src={src}
                      alt="omg"
                    />
                    <h3 className="w-[50px] justify-center">{name} </h3>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="w-full text-center mt-4 text-lg text-white  ">
            {title === "Latest Mobiles" ? (
              <button
                className=" bg-[#FF8C00] font-medium bg-gradient-to-b text-sm p-2 pl-4 pr-4 rounded-md  shadow-inner"
                onClick={() => {
                  navigate("/LatestMobile");
                }}
              >
                Explore More
              </button>
            ) : title === "Top 6 Compaines" ? // <button
            //   className=" bg-[#FF8C00] font-medium bg-gradient-to-b text-sm p-2 pl-4 pr-4 rounded-md  shadow-inner"
            //   onClick={() => {

            //     navigate("/TopCompanies");
            //   }}
            // >
            //   Explore More
            // </button>
            null : (
              <button
                className=" bg-[#FF8C00] font-medium bg-gradient-to-b text-sm p-2 pl-4 pr-4 rounded-md  shadow-inner"
                onClick={() => {
                  navigate("/TopFans");
                }}
              >
                Explore More
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterMobileCard;
