import { baseURL } from "api/baseURL";
import axios from "axios";
import {
  GADGET_BYID_FAIL,
  GADGET_BYID_REQUEST,
  GADGET_BYID_SUCCESS,
  GADGET_GET_FAIL,
  GADGET_GET_REQUEST,
  GADGET_GET_SUCCESS,
} from "redux/Constant/Constant";

export const GadgetGet = () => async (dispatch) => {
  try {
    //  dispatch({type:GADGET_GET_REQUEST})

    const { data } = await axios.get(`${baseURL}/api/v1/gadget`);
    dispatch({ type: GADGET_GET_SUCCESS, payload: data.succes });
  } catch (err) {
    dispatch({ type: GADGET_GET_FAIL, payload: err });
  }
};

export const GadgetSingleAction = (id) => async (dispatch) => {
  try {
    //  dispatch({type:GADGET_BYID_REQUEST})

    const { data } = await axios.get(`${baseURL}/api/v1/gadget/${id}`);
    dispatch({ type: GADGET_BYID_SUCCESS, payload: data.success });
  } catch (err) {
    dispatch({ type: GADGET_BYID_FAIL, payload: err });
  }
};
