import React from "react";
import { useEffect, useState,useRef } from "react";
import { RightSideMenu, LeftSideMenu } from "megaComponents";

// import { useNavigate } from "react-router";
import {
  Footer,
  MobilelistCard,
  Navbar,
  MobileBrandsNames,
  NewsCard,
} from "components";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { getMobileById } from "../redux/actions/mobileActions";
// import {useNavigate} from 'react-router'
import { useNavigate } from "react-router";
import Slider from "../components/Slider";
import { getMobiles } from "../redux/actions/mobileActions";
import { baseURL } from "api/baseURL";
import { Helmet } from "react-helmet";
import HelmetComponent from "components/HelmetComponent";

export let globalPageNo = 1;
export function globalPageNoSetter(p) {
  globalPageNo = p;
}
export let globalNext = false;
export function globalSetNext(n) {
  globalNext = n;
}
const Home = () => {
  globalPageNo = 1;


// const titleRef=useRef(null)
//   useEffect(()=>{
// document.title=titleRef.current.textContent;
//   },[])
  // const navigate=useNavigate();
  const [datas, setdat] = useState([]);
  const [second, setsecond] = useState([]);
  const [third, setthird] = useState([]);
  const [forth, setforth] = useState([]);
  const [fifth, setfifth] = useState([]);
  const [sixth, setsixth] = useState([]);
  const [seventh, setseventh] = useState([]);
  const [data, setData] = useState();

  const PF = `${baseURL}/images/`;
  // const PF='http://localhost:8080/images/'
  const [pageNo, setPageNumber] = useState(1);
  const dispatch = useDispatch();
  const { allMobiles, moreMobile, isFilter } = useSelector(
    (state) => state.mobileReducer
  );
  //actions start
  const handleFetchMobiles = (page) => dispatch(getMobiles(page));
  // const handleSetSingleMobile = (value) => dispatch(getSingleMobile(value));

  useEffect(() => {
    const func = async () => {
      const GET = await axios.get(`${baseURL}/api/v1/featurephones`);
      // const GET =await axios.get('http://localhost:8080/api/v1/featurephones');

      setdat(GET.data.success[0]);
      setsecond(GET.data.success[1]);
      setthird(GET.data.success[2]);
      setforth(GET.data.success[3]);
      setfifth(GET.data.success[4]);
      setsixth(GET.data.success[5]);
      setseventh(GET.data.success[6]);

      // handleFetchMobiles(pageNo);
    };
    func();
    handleFetchMobiles(pageNo);
  }, [pageNo]);
  var _id = sixth.phoneId;
  let navigate = useNavigate();
  // const handleSetSingleMobile = (value) => dispatch(getSingleMobile(value));
  const handleSetSingleMobile = (value) => dispatch(getMobileById(value));

  useEffect(() => {
    const GetNews = async () => {
      try {
        const res = await axios.get(`${baseURL}/api/v1/news`);
        setData(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    GetNews();
  }, []);
  return (
    <React.Fragment>
      <Navbar />

      <div className="mainContent  md:space-x-2   lg:mt-7 flex flex-col-reverse lg:flex-row px-3  ">
        <div className="lg:w-[20%]">
          <MobileBrandsNames />
          <LeftSideMenu />
        </div>

        <div className="lg:w-[59.7%] min-h-screen  pb-0 lg:pb-[0rem]  flex flex-col bg-[#D2DCE7] mt-1 lg:mt-0  order-3 lg:order-2 bg-center   ')]">
          <div className="  ">
            {" "}
            <Slider />
          </div>
          <div className="flex justify-center  ">
            <div className="  pr-2">
              {/* <Link to={`/xiaomi-11-lite-5g-ne/${datas.phoneId}`}> */}
              {/* </Link> */}
              <img
                className="w-full h-full border cursor-pointer"
                src={PF + datas.featureMobiles}
                onClick={() => {
                  if (datas.phoneId == "") {
                    return "";
                  } else {
                    // localStorage.clear();
                    // localStorage.setItem("detailId", datas.phoneId);
                    // handleSetSingleMobile(datas.phoneId);
                    // navigate(`/phonespec/${datas.phoneId}`);
                    navigate(`${datas.phoneId}`);
                  }
                }}
              />
            </div>
            <div className="lg:min-w-[50%] flex flex-col pl-2 space-y-2 ">
              <img
                onClick={() => {
                  if (second.phoneId == "") {
                    return "";
                  } else {
                    // localStorage.clear();
                    // localStorage.setItem("detailId", second.phoneId);
                    // handleSetSingleMobile(second.phoneId);
                    // navigate(`/phonespec/${second.phoneId}`);
                    navigate(`${second.phoneId}`);
                  }
                }}
                className="w-full h-[5rem] cursor-pointer lg:h-[10rem] border border-light-200 "
                // src="BannerImg1"
                src={PF + second.featureMobiles}
              />

              <img
                className="w-full h-[5rem] lg:h-[10rem] cursor-pointer border border-light-200"
                // src="BannerImg2"
                onClick={() => {
                  if (third.phoneId == "") {
                    navigate("/");
                  } else {
                    // localStorage.clear();
                    // localStorage.setItem("detailId", third.phoneId);
                    // handleSetSingleMobile(third.phoneId);
                    // navigate(`/phonespec/${third.phoneId}`);
                    navigate(`${third.phoneId}`);
                  }
                }}
                src={PF + third.featureMobiles}
              />

              <img
                onClick={() => {
                  if (forth.phoneId == "") {
                    return "";
                  } else {
                    // localStorage.clear();
                    // localStorage.setItem("detailId", forth.phoneId);
                    // handleSetSingleMobile(forth.phoneId);
                    // navigate(`/phonespec/${forth.phoneId}`);
                    navigate(`${forth.phoneId}`);
                  }
                }}
                className="w-full h-[5rem] cursor-pointer lg:h-[10rem]  border border-light-200"
                // src="BannerImg3"
                src={PF + forth.featureMobiles}
              />
            </div>
          </div>
          <div className="">
            <img
              onClick={() => {
                if (fifth.phoneId == "") {
                  return "";
                } else {
                  // alert('click')
                  // handleSetSingleMobile(sixth.phoneId);

                  // localStorage.clear("phoneId") ;
                  // localStorage.setItem("detailId",sixth.phoneId)
                  // localStorage.clear();
                  // localStorage.setItem("detailId", fifth.phoneId);
                  // handleSetSingleMobile(fifth.phoneId);
                  // navigate(`phonespec/${fifth.phoneId}`);

                  // window.location.replace(`/phonespec/${fifth.phoneId}`)

                  navigate(`${fifth.phoneId}`);
                }
              }}
              className="w-full mt-3 cursor-pointer h-[7rem] lg:h-[13rem]  border border-light-200"
              // src="BannerImg1"
              src={PF + fifth.featureMobiles}
            />
            {/* <img 
              onClick={() => {
                navigate(`/phonespec/${sixth.phoneId}`
                );
                
              }}
              className="w-full mt-3 h-[7rem] lg:h-[13rem] cursor-pointer  border border-light-200"
              src={PF+sixth.featureMobiles} 

            /> */}
            {/* <Link to={`/phonespec/${seventh.phoneId}`}>

            <img
              className="w-full mt-3 h-[13rem]  cursor-pointer border border-light-200"
              src={PF+seventh.featureMobiles} 

            />
            </Link> */}
          </div>
          <div className="mt-6">
            {data?.map((news, index) => {
              if (index > 2) {
                return;
              }
              return (
                <NewsCard
                  key={news._id}
                  imgsrc={news.image}
                  title={news.title}
                  desc={news.descreption}
                  date={news.date}
                  id={news._id}
                />
              );
            })}
          </div>
          <div className="mt-[2rem] "></div>
          <div className="lg:pl-4 lg:pr-4 rounded-md bg-center  bg-[#D2DCE7] min-h-screen border-8 ">
            <div className="flex-1  p-4 pl-1 pr-1 ">
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3  lg:grid-cols-3 gap-2 gap-y-6">
                {allMobiles !== undefined
                  ? allMobiles.map((item) => {
                      return <MobilelistCard item={item} key={item._id} />;
                    })
                  : "...loading"}
              </div>
            </div>
          </div>
        </div>
        <div className="lg:w-[20%]  text-center order-2  lg:absolute lg:order-3  top-3 right-0">
          <div className="flex flex-col">
            <button
              className="w-auto cursor-pointer border-2 bg-transparent p-1 mt-5 lg:mt-0  rounded-[1rem]"
              onClick={() => navigate("/advancesearch")}
            >
              Advance Searche
            </button>
            <button
              className="w-auto cu border-2 bg-transparent mt-8 p-1  rounded-[1rem]"
              onClick={() => navigate("/reviews")}
            >
              Videos Reviews
            </button>
            <button
              className="w-auto text-white  bg-[#F4871E] font-semibold text-md mt-3 p-1 pl-[2rem] pr-[2rem] rounded-[1rem]"
              onClick={() => navigate("/uploadad")}
            >
              Buy And Sell <br /> Smart Phone
            </button>
          </div>
          <RightSideMenu />
        </div>
      </div>

      <div className="mt-[10rem]  w-full">
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default Home;
