import React, { useEffect } from "react";
import { FiPlus, FiMinus } from "react-icons/fi";
import FaqData from "./FaqData";
import { useState } from "react";
import { FaMinus } from "react-icons/fa";
import { baseURL } from "api/baseURL";
import axios from "axios";

function QuestionAsk() {
  const [faq, setFaq] = useState([]);
  const [cliked, setcliked] = useState(false);

  const toggle = (index) => {
    if (cliked === index) {
      return setcliked(null);
    }
    setcliked(index);
  };

  useEffect(() => {
    const fatchApi = async () => {
      try {
        const { data } = await axios.get(`${baseURL}/api/v1/question`);
        setFaq(data);
      } catch (error) {
        console.log(error);
      }
    };
    fatchApi();
  }, []);
  return (
    <div className="mt-1 md:max-w-[47rem] bg-warmGray-100 grid  p-3 shadow-gray-600 shadow-l rounded-lg ">
      {faq.map((item, index) => (
        <div onClick={() => toggle(index)} key={index}>
          <h1 className=" text-xl m-2 border-2  p-4 rounded-xl shadow-gray-500 bg-[#D2DCE7] ">
            <div className="flex justify-between mb-2">
              {" "}
              <span className="font-bold font-sans"> {item.question}</span>
              <span className="text-2xl ">
                {cliked === index ? <FiMinus /> : <FiPlus />}
              </span>
            </div>
            {cliked === index ? (
              <div className=" flex justify-between p-4 rounded-xl shadow-gray-500 bg-warmGray-100 ">
                {" "}
                <p>{item.answer}</p>{" "}
              </div>
            ) : null}
          </h1>
        </div>
      ))}
    </div>
  );
}

export default QuestionAsk;
