import React from "react";
import { ImageAsset } from "components";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { baseURL } from "api/baseURL";

const NewsCard = (props) => {
  const { id, imgsrc,alt, title, desc, date } = props;
  // const PF = "http://localhost:8080/images/";
  const PF = `${baseURL}`;
  // const fp='http://localhost:8080/images/';
  const fp = `${Link}`;

  var newsTitle = "";
  if (title == undefined) {
    newsTitle = "_";
  } else {
    newsTitle = title.replace(/\s/g, "-");
  }

  return (
    <React.Fragment>
      <div className=" items-start bg-[#FFF] p-3 mt-2 lg:items-start mr-2 ml-2 rounded-lg relative border-[1px]">
        <div className="w-full ">
          <div className="">
            <Link to={`/news/${newsTitle.replace(/\s/g, "-")}/${id}`}>
              <img
                className="h-[15rem] w-full object-contain rounded-2xl border-2"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  {
                  }
                  currentTarget.src = `${window.location.origin}/static/media/newlogo.b0c72215337ceecd7aac.png`;
                }}
                src={PF + imgsrc}
                alt={alt}
              />
              <h1 className="p-0 text-2xl text-white font-bold mb-2 font-serif drop-shadow-3xl absolute -translate-y-[120%] bg-opacity-20 bg-[#000] mr-5 ml-3">
                {title}
              </h1>
            </Link>

            {/* )) } */}

            {/* <Link className="hover:underline " to={`/news/detail/${id}/${newsTitle.replace(/\s/g, '-')}`}> */}
            {/* <Link className="hover:underline " to={`/news/${newsTitle.replace(/\s/g, '-')}/${id}`}>
              
            </Link> */}
          </div>
          {/* <Link  to={`/news/detail/${id}/${newsTitle.replace(/\s/g, '-')}`}> */}
          <Link to={`/news/${newsTitle.replace(/\s/g, "-")}/${id}`}>
            <h3 className="text-xs font-normal mt-2 pb-6">
              <div
                className="leading-relaxed pr-4 text-xl text-black overflow-x-hidden"
                dangerouslySetInnerHTML={{
                  __html:
                    desc.length > 200
                      ? `${desc.slice(0, 200).trim()}...`
                      : desc,
                }}
              />
              {/* {desc} */}
            </h3>
          </Link>

          <div className="justify-center text-right  text-xs lg:mr-20">
            <label htmlFor="">{date}</label>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NewsCard;
