import React from "react";
import { Footer, Navbar, NewsCard } from "components";
import { RightSideMenu } from "megaComponents";
import { useNavigate } from "react-router";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { baseURL } from "api/baseURL";
import HelmetComponent from "components/HelmetComponent";

const NewsCardData = [
  {
    imgsrc: "Infinix",
    title: "Samsung Galaxy S20 will get Android 12- Note",
    desc: "Samsung's been testing the Android 12-based One UI 4 beta on the Galaxy S20 series for some time now",
    date: "27 Dec 2021",
  },
];

const News = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  useEffect(() => {
    const GetReview = async () => {
      try {
        const res = await axios.get(`${baseURL}/api/v1/news`);
        setData(res.data);
        console.log(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    GetReview();
  }, []);

  return (
    <React.Fragment>
      <Navbar />
      {/* <HelmetComponent
        title="News - mymobilesguru.com"
        description="Description - News"
        image="https://picsum.photos/300"
      /> */}
      <div className="mainContent mt-8 flex flex-col lg:flex-row px-1 lg:px-10">
        <div className="lg:w-[20%] order-2 ">
          <div className="flex flex-col">
            <button
              className="w-auto border-2 bg-transparent p-1 mt-5 lg:mt-0  rounded-[1rem]"
              onClick={() => navigate("/advancesearch")}
            >
              Advance Search
            </button>
            <button
              className="w-auto border-2 bg-transparent mt-8 p-1  rounded-[1rem]"
              onClick={() => navigate("/reviews")}
            >
              Videos Reviews
            </button>
            <button
              className="w-auto text-white   bg-[#FF8C00] font-semibold text-md mt-3 p-1 pl-[2rem] pr-[2rem] rounded-[1rem]"
              onClick={() => navigate("/uploadad")}
            >
              Buy And Sell <br /> Smart Phone
            </button>
          </div>
          {/* 
          {rightMenu.map(({ title, heading, description }) => {
            return (
              <FilterCard title={title} heading={heading}>
                {description.map((item) => {
                  return <h1>{item}</h1>;
                })}
              </FilterCard>
            );
          })} */}

          <RightSideMenu />
        </div>

        <div className="w-full lg:w-[70%] order-1 h-full  pb-0 lg:pb-[20rem]  flex flex-col lg:pl-7 lg:pr-7 mt-[1rem]  lg:ml-3 lg:mr-3 lg:mt-0  lg:order-2 bg-center ">
          <div className="flex-col">
            <div className="video w-full lg:w-full h-[10rem] rounded-3xl bg-cover lg:bg-cover  bg-no-repeat  bg-[url('assets/images/newsBanner.png')]">
              {/* <ImageAsset
                className="w-full h-[10rem] lg:h-[25rem]  "
                src="newsBanner"
              /> */}
              <h1 className="p-10  lg:p-12 lg:pl-16  text-center  text-2xl lg:text-5xl font-bold text-white">
                News
              </h1>
            </div>
          </div>
          {/* cards */}

          <div className="mt-6">
            {data.map((news) => {
              return (
                <NewsCard
                  key={news._id}
                  imgsrc={news.image}
                  alt={news.alt}
                  title={news.title}
                  desc={news.descreption}
                  date={news.date}
                  id={news._id}
                />
              );
            })}
          </div>
          {/* <div className="w-full text-center mt-5">
            <button className="w-full lg:w-[70%] text-center text-white font-medium  bg-buttonBgColor bg-gradient-to-b text-sm lg:text-md p-4 pl-10 rounded-[1.8rem] mt-5 from-buttonBgColorGradian1 to-buttonBgcolorGradian2">
              Explore More
            </button>
          </div> */}
        </div>
      </div>
      <div className="mt-[10rem]">
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default News;
